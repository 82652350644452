import * as yup from "yup";
import moment from "moment";
import { COUNTRIES } from "../User/UserProfile";
import Password from "../Auth/Password";

export const validateSchema = payload =>
    yup.object().shape({
        email: emailValidation(),
        firstName: stringNameValidation("first name"),
        lastName: stringNameValidation("last name"),
        dateOfBirth: dateOfBirthValidation(),
        gender: genderValidation(["Male", "Female"]),
        countryCode: yup.string().required(),
        postalCode: postalCodeValidation(payload.optionalPostcode, payload.countryCode),
        mobileNumber: mobileValidationByCountryCode(payload.countryCode, payload.options)
    });

export const stringNameValidation = fieldName =>
    yup
        .string()
        .nullable()
        .required(`Please provide a ${fieldName}.`);

export const emailValidation = () =>
    yup
        .string()
        .email("Please provide a valid email address.")
        .required("Please input your email");

export const genderValidation = options =>
    yup
        .string()
        .oneOf(options, "Please select a gender.")
        .required();

export const dateOfBirthValidation = () =>
    yup
        .date()
        .transform((value, originalValue) => {
            value = moment(originalValue, "YYYY-MM-DD");
            // returns either a validated date or a broken date to force the type error
            return value.isValid() ? value.toDate() : new Date("");
        })
        .typeError("Please provide a valid date of birth.")
        // >= 16 years old
        .max(
            moment()
                .subtract(16, "years")
                .toISOString(),
            "Sorry, you must be over 16 years of age to use the SiSU Portal."
        )
        .required();

/**
 * Given various configuration options and a country code, generates a validation schema for the postal code field
 *
 * @param {boolean} optionalPostcode
 * @param {String} countryCode
 * @return {*}
 * @private
 */
export const postalCodeValidation = (optionalPostcode = false, countryCode) => {
    if (optionalPostcode) {
        return yup.string().nullable();
    }

    switch (countryCode) {
        case COUNTRIES.AUSTRALIA:
            return yup
                .string()
                .matches(/^([0-9]{4})$/, "Please provide a valid Australian postcode (4 digits)")
                .nullable()
                .required("Please provide a valid Australian postcode (4 digits)");

        case COUNTRIES.UNITED_KINGDOM:
            return yup
                .string()
                .nullable()
                .matches(
                    // inspired by https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation
                    /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) [0-9][A-Z]{2}|BFPO [0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} [0-9]{2}|GE CX|GIR 0A{2}|SAN TA1)$/i,
                    "Please enter a full valid UK postcode, with a space (eg. E2 8AA)."
                )
                .required("Please provide a valid UK postcode.");

        case COUNTRIES.IRELAND:
            return yup
                .string()
                .nullable()
                .matches(
                    // inspired by https://autoaddress.com/en-ie/support/developer-centre/faqs/do-you-have-a-regex-for-eircode
                    /^(a(4[125s]|6[37]|7[5s]|[8b][1-6s]|9[12468b])|c1[5s]|d([0o][1-9sb]|1[0-8osb]|2[024o]|6w)|e(2[15s]|3[24]|4[15s]|[5s]3|91)|f(12|2[368b]|3[15s]|4[25s]|[5s][26]|9[1-4])|h(1[2468b]|23|[5s][34]|6[25s]|[79]1)|k(3[246]|4[5s]|[5s]6|67|7[8b])|n(3[79]|[49]1)|p(1[247]|2[45s]|3[126]|4[37]|[5s][16]|6[17]|7[25s]|[8b][15s])|r(14|21|3[25s]|4[25s]|[5s][16]|9[35s])|t(12|23|34|4[5s]|[5s]6)|v(1[45s]|23|3[15s]|42|9[2-5s])|w(12|23|34|91)|x(3[5s]|42|91)|y(14|2[15s]|3[45s]))[ ]?[abcdefhknoprtsvwxy0-9]{4}$/i,
                    "Please enter a full valid Eircode (eg. D08 XY00)."
                )
                .required("Please provide a valid Eircode.");

        case COUNTRIES.SOUTH_AFRICA:
            return yup
                .string()
                .matches(/^([0-9]{4})$/, "Please provide a valid South African postcode (4 digits).")
                .nullable()
                .required("Please provide a valid South African postcode.");

        case COUNTRIES.NEW_ZEALAND:
            return yup
                .string()
                .nullable()
                .required("Please provide a valid postcode");

        case COUNTRIES.SWITZERLAND:
            return yup
                .string()
                .matches(/^([1-9][0-9]{3})$/, "Please provide a valid postcode (4 digits).")
                .nullable()
                .required("Please provide a valid postcode.");

        case COUNTRIES.SLOVAKIA:
            return yup
                .string()
                .matches(/^(([0-9]{5})|([0-9]{3}[ ]{0,1}[0-9]{2}))$/, "yup_messages.postcode.sk.match")
                .nullable()
                .required("yup_messages.postcode.sk.required");

        case COUNTRIES.DENMARK:
            return yup
                .string()
                .matches(/^[1-9][0-9]{3}$/, "yup_messages.postcode.dk.match")
                .nullable()
                .required("yup_messages.postcode.dk.required");

        case COUNTRIES.SWEDEN:
            return yup
                .string()
                .matches(/^\d{3}\s?\d{2}$/, "yup_messages.postcode.se.match")
                .nullable()
                .required("yup_messages.postcode.se.required");

        case COUNTRIES.NETHERLANDS:
            return yup
                .string()
                .matches(/^\d{4}\s?[A-Z]{2}$/, "yup_messages.postcode.nl.match")
                .nullable()
                .required("yup_messages.postcode.nl.required");

        case COUNTRIES.FRANCE:
            return yup
                .string()
                .matches(/^\d{5}$/, "yup_messages.postcode.fr.match")
                .nullable()
                .required("yup_messages.postcode.fr.required");

        case COUNTRIES.POLAND:
            return yup
                .string()
                .matches(/^\d{2}-\d{3}$/, "yup_messages.postcode.pl.match")
                .nullable()
                .required("yup_messages.postcode.pl.required");

        case COUNTRIES.SPAIN:
            return yup
                .string()
                .matches(/^\d{5}$/, "yup_messages.postcode.es.match")
                .nullable()
                .required("yup_messages.postcode.es.required");

        case COUNTRIES.CZECHIA:
            return yup
                .string()
                .matches(/^\d{3}\s?\d{2}$/, "yup_messages.postcode.cz.match")
                .nullable()
                .required("yup_messages.postcode.cz.required");

        case COUNTRIES.BELGIUM:
            return yup
                .string()
                .matches(/^\d{4}$/, "yup_messages.postcode.be.match")
                .nullable()
                .required("yup_messages.postcode.be.required");

        case COUNTRIES.LATVIA:
            return yup
                .string()
                .matches(/^LV-\d{4}$/, "yup_messages.postcode.lv.match")
                .nullable()
                .required("yup_messages.postcode.lv.required");

        case COUNTRIES.ITALY:
            return yup
                .string()
                .matches(/^\d{5}$/, "yup_messages.postcode.it.match")
                .nullable()
                .required("yup_messages.postcode.it.required");

        case COUNTRIES.FINLAND:
            return yup
                .string()
                .matches(/^\d{5}$/, "yup_messages.postcode.fi.match")
                .nullable()
                .required("yup_messages.postcode.fi.required");

        case COUNTRIES.NORWAY:
            return yup
                .string()
                .matches(/^\d{4}$/, "yup_messages.postcode.no.match")
                .nullable()
                .required("yup_messages.postcode.no.required");

        case null:
            return yup.string().nullable();

        case COUNTRIES.GERMANY:
            return yup
                .string()
                .matches(/^\d{5}$/, "yup_messages.postcode.de.match")
                .nullable()
                .required("yup_messages.postcode.de.required");

        default:
            return yup.string().nullable();
    }
};

/**
 * Given a country code, generates a validation schema for the mobile number field
 * @param {String} countryCode
 * @param {object} options optional param for validation options
 * @param {{ AU: string, GB: string, ZA: string }} options.message
 * @return {*}
 * @private
 */
export const mobileValidationByCountryCode = (countryCode, options = { message: {} }) => {
    const errorMessages = {
        [COUNTRIES.AUSTRALIA]: "Please provide a valid mobile number (04XX XXX XXX).",
        [COUNTRIES.UNITED_KINGDOM]: "Please provide a valid UK mobile number (07XXX XXXXXX).",
        [COUNTRIES.SOUTH_AFRICA]:
            "Please provide a valid South African mobile number (10-digits, starting with 06, 07, or 08)",
        ...options.message
    };

    switch (countryCode) {
        case COUNTRIES.AUSTRALIA:
            return yup
                .string()
                .matches(/^((04){1}[0-9]{2}\s*[0-9]{3}\s*[0-9]{3})$/, {
                    message: errorMessages[countryCode],
                    excludeEmptyString: true
                })
                .nullable();

        case COUNTRIES.UNITED_KINGDOM:
            return yup
                .string()
                .matches(/^07[0-9]{3}\s?[0-9]{6}$/, {
                    message: errorMessages[countryCode],
                    excludeEmptyString: true
                })
                .nullable();

        case COUNTRIES.SOUTH_AFRICA:
            return yup
                .string()
                .matches(/(^0[678][0-9]((\d{7})|( |-)((\d{3}))( |-)(\d{4})|( |-)(\d{7})))$/, {
                    message: errorMessages[countryCode],
                    excludeEmptyString: true
                })
                .nullable();

        default:
            return yup.string().nullable();
    }
};

export const passwordValidation = () => {
    return yup
        .string()
        .required("Password is required Field")
        .test("password-valid", Password.errors.requirement, function(val) {
            const PasswordUtil = new Password();
            return val ? PasswordUtil.isPasswordValid(val) : false;
        });
};
