import { UserJourneyMap } from "../UserJourney";
import { sanitiseEmailAddress } from "../helpers/DataCleaner";
import { validateSchema } from "../Validation/UserProfileValidation";
import { values } from "ramda";

export const COUNTRIES = {
    AUSTRALIA: "AU",
    GERMANY: "DE",
    IRELAND: "IE",
    UNITED_KINGDOM: "GB",
    SOUTH_AFRICA: "ZA",
    NEW_ZEALAND: "NZ",
    SWITZERLAND: "CH",
    SLOVAKIA: "SK",
    OTHER: "ZZ",
    DENMARK: "DK",
    SWEDEN: "SE",
    NETHERLANDS: "NL",
    FRANCE: "FR",
    POLAND: "PL",
    SPAIN: "ES",
    CZECHIA: "CZ",
    BELGIUM: "BE",
    LATVIA: "LV",
    ITALY: "IT",
    FINLAND: "FI",
    NORWAY: "NO"
};

export const INFRASTRUCTURE_REGIONS = {
    AUSTRALIA: "AU",
    UNITED_KINGDOM: "UK"
};

/**
 * @typedef {{code: string, name: string, region: string}} CountryMapEntry
 * @type {[CountryMapEntry]}
 */
export const CountryMap = [
    { code: COUNTRIES.AUSTRALIA, name: "Australia", region: INFRASTRUCTURE_REGIONS.AUSTRALIA },
    { code: COUNTRIES.NEW_ZEALAND, name: "New Zealand", region: INFRASTRUCTURE_REGIONS.AUSTRALIA },
    { code: COUNTRIES.GERMANY, name: "Germany", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.IRELAND, name: "Ireland", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.UNITED_KINGDOM, name: "United Kingdom", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.SOUTH_AFRICA, name: "South Africa", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.SWITZERLAND, name: "Switzerland", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.SLOVAKIA, name: "Slovakia", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.DENMARK, name: "Denmark", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.SWEDEN, name: "Sweden", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.NETHERLANDS, name: "Netherlands", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.FRANCE, name: "France", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.POLAND, name: "Poland", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.SPAIN, name: "Spain", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.CZECHIA, name: "Czechia", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.BELGIUM, name: "Belgium", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.LATVIA, name: "Latvia", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.ITALY, name: "Italy", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.FINLAND, name: "Finland", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM },
    { code: COUNTRIES.NORWAY, name: "Norway", region: INFRASTRUCTURE_REGIONS.UNITED_KINGDOM }
];

/**
 * @param {string} region
 * @returns {[CountryMapEntry]}
 */
const getCountriesByRegionCode = region =>
    CountryMap.filter(entry => entry.region.toLowerCase() === region.toLowerCase());

/**
 * @param {string} name
 * @returns {CountryMapEntry}
 */
const getCountryByName = name => {
    name = name || "";
    return (
        CountryMap.filter(entry => entry.name.toLowerCase() === name.toLowerCase())[0] || {
            code: "",
            name: "",
            region: ""
        }
    );
};

/**
 * @param {string} name
 * @returns {string}
 */
const getCountryCodeByName = name => getCountryByName(name).code;

/**
 * @param {string} code
 * @returns {CountryMapEntry}
 */
const getCountryByCode = code => {
    code = code || "";
    return (
        CountryMap.filter(entry => entry.code.toLowerCase() === code.toLowerCase())[0] || {
            code: "",
            name: "",
            region: ""
        }
    );
};

/**
 * @param {string} code
 * @returns {CountryMapEntry}
 */
const getRegionCodeByCountryCode = code => getCountryByCode(code).region;

/**
 * @param {string} code
 * @returns {string}
 */
const getCountryNameByCode = code => getCountryByCode(code).name;

export const CountryHelper = {
    getCountryByName,
    getCountryCodeByName,
    getCountryByCode,
    getCountryNameByCode,
    getCountriesByRegionCode,
    getRegionCodeByCountryCode
};

export default class UserProfile {
    /**
     * @param {{previousEmails: ([]), firstName: String, lastName: String, gender: String, countryCode: String, mobileNumber: (String|null), postalCode: String, dateOfBirth: String, email: string, extraData: Object}} userProfile
     * @param {{availableCountries: [String], optionalPostcode: boolean}} configuration
     */
    constructor(userProfile = {}, configuration = {}) {
        this._email = null;
        this._previousEmails = [];
        this._firstName = null;
        this._lastName = null;
        this._dateOfBirth = null;
        this._gender = null;
        this._countryCode = null;
        this._postalCode = null;
        this._mobileNumber = null;
        this._extraData = {};

        this._configuration = {
            availableCountries: Object.values(COUNTRIES),
            optionalPostcode: true,
            ...configuration
        };

        this.initialise(userProfile);
    }

    /**
     * Initialises the User Profile with a variable-sized object
     *
     * @param {{previousEmails: [], firstName: String, lastName: String, gender: String, countryCode: String, mobileNumber: String|null, postalCode: String, dateOfBirth: String, email: String, extraData: Object}} userProfile
     * @param {boolean} validate
     * @throws
     */
    initialise(userProfile, validate = false) {
        if (validate) {
            const errors = this.validate(userProfile);
            if (errors.length > 0) {
                throw new Error(`UserProfile:initialise() failed validation: ${errors.concat(", ")}`);
            }
        }

        //NB: important to set this first
        this._setPreviousEmails(userProfile.previousEmails || this._previousEmails);

        this._setEmail(userProfile.email || this._email);
        this._setFirstName(userProfile.firstName || this._firstName);
        this._setLastName(userProfile.lastName || this._lastName);
        this._setDateOfBirth(userProfile.dateOfBirth || this._dateOfBirth);
        this._setGender(userProfile.gender || this._gender);
        this._setCountryCode(userProfile.countryCode || this._countryCode);
        this._setPostalCode(userProfile.postalCode || this._postalCode);
        this._setMobileNumber(userProfile.mobileNumber || this._mobileNumber);
        this._setExtraData(userProfile.extraData || this._extraData);
    }

    /**
     * Updates the user profile with provided values
     *
     * @param {{firstName: String, lastName: String, gender: String, countryCode: String, mobileNumber: String|null, postalCode: String, dateOfBirth: String, email: String}} userProfile
     */
    update(userProfile) {
        const { email, firstName, lastName, dateOfBirth, gender, countryCode, postalCode, mobileNumber } = userProfile;

        this._setEmail(email);
        this._setFirstName(firstName);
        this._setLastName(lastName);
        this._setDateOfBirth(dateOfBirth);
        this._setGender(gender);
        this._setCountryCode(countryCode);
        this._setPostalCode(postalCode);
        this._setMobileNumber(mobileNumber);
    }

    /**
     * Returns if this UserProfile utility instance is new or not
     *
     * A bit of a hack as it simply looks to see if the email address has been defined.
     * @return {boolean}
     */
    isInitialised() {
        return !!this.email;
    }

    loadValuesFromAnswers(answers) {
        for (let key in answers) {
            const answer = answers[key];
            const answerValue = answer[0].answer;

            switch (key) {
                case UserJourneyMap.questionCodes.email:
                    this._setEmail(answerValue);
                    break;

                case UserJourneyMap.questionCodes.firstName:
                    this._setFirstName(answerValue);
                    break;

                case UserJourneyMap.questionCodes.lastName:
                    this._setLastName(answerValue);
                    break;

                case UserJourneyMap.questionCodes.dateOfBirth:
                    this._setDateOfBirth(answerValue);
                    break;

                case UserJourneyMap.questionCodes.gender:
                    this._setGender(answerValue);
                    break;

                case UserJourneyMap.questionCodes.countryOfResidence:
                    this._setCountryCode(answerValue);
                    break;

                case UserJourneyMap.questionCodes.postalCode:
                    this._setPostalCode(answerValue);
                    break;

                case UserJourneyMap.questionCodes.mobileNumber:
                    this._setMobileNumber(answerValue);
                    break;
            }
        }
    }

    /**
     * Returns a serialised User Profile
     *
     * @return {{previousEmails: ([]), firstName: String, lastName: String, gender: String, countryCode: String, mobileNumber: (String|null), postalCode: String, dateOfBirth: String, email: string}}
     */
    toJSON() {
        return {
            email: this._email,
            previousEmails: this._previousEmails,
            firstName: this._firstName,
            lastName: this._lastName,
            dateOfBirth: this._dateOfBirth,
            gender: this._gender,
            countryCode: this._countryCode,
            postalCode: this._postalCode,
            mobileNumber: this._mobileNumber,
            extraData: this._extraData
        };
    }

    /**
     * Validates a given payload for a user profile update
     *
     * @param {Object} payload
     * @return {[]}
     */
    validate(payload) {
        let errors = [];

        const validationPayload = { optionalPostcode: this._configuration.optionalPostcode, ...payload };
        const validationSchema = validateSchema(validationPayload);

        try {
            validationSchema.validateSync(payload, { abortEarly: false, stripUnknown: true });
        } catch (error) {
            errors = error.errors;
        }

        return errors;
    }

    /**
     * Getter for email
     * @return {string}
     */
    get email() {
        return this._email;
    }

    /**
     * Setter for Email
     * @param {String} email
     * @private
     */
    _setEmail(email) {
        if (typeof email === "string" && email.length) {
            const sanitisedEmail = sanitiseEmailAddress(email);
            if (sanitisedEmail !== this._email) {
                // preserve the last email
                if (this._email) {
                    this._previousEmails.push(this._email);
                }

                this._email = sanitisedEmail;
            }
        }
    }

    /**
     * Getter for Previous Emails
     * @return {[String]}
     */
    get previousEmails() {
        return this._previousEmails;
    }

    /**
     * Setter for Previous Emails
     * @param previousEmails
     * @private
     */
    _setPreviousEmails(previousEmails) {
        this._previousEmails = previousEmails;
    }

    /**
     * Getter for First Name
     * @return {string}
     */
    get firstName() {
        return this._firstName;
    }

    /**
     * Setter for First Name
     * @param {String} firstName
     * @private
     */
    _setFirstName(firstName) {
        this._firstName = firstName;
    }

    /**
     * Getter for Last Name
     * @return {String}
     */
    get lastName() {
        return this._lastName;
    }

    /**
     * Setter for Last Name
     * @param {String} lastName
     * @private
     */
    _setLastName(lastName) {
        this._lastName = lastName;
    }

    /**
     * Getter for Date of Birth
     * @return {String}
     */
    get dateOfBirth() {
        return this._dateOfBirth;
    }

    /**
     * Setter for Date of Birth
     * @param {String} dateOfBirth
     * @private
     */
    _setDateOfBirth(dateOfBirth) {
        this._dateOfBirth = dateOfBirth;
    }

    /**
     * Getter for Gender
     * @return {String}
     */
    get gender() {
        return this._gender;
    }

    /**
     * Setter for Gender
     * @param {String} gender
     * @private
     */
    _setGender(gender) {
        this._gender = gender;
    }

    /**
     * Getter for Country Code
     * @return {String}
     */
    get countryCode() {
        return this._countryCode;
    }

    /**
     * Setter for Country Code
     * @param {String} countryCode
     * @private
     */
    _setCountryCode(countryCode) {
        this._countryCode = countryCode;
    }

    /**
     * Getter for Postal Code
     * @return {String}
     */
    get postalCode() {
        return this._postalCode;
    }

    /**
     * Setter for Postal Code
     * @param {String} postalCode
     * @private
     */
    _setPostalCode(postalCode) {
        this._postalCode = postalCode;
    }

    /**
     * Getter for Mobile Number
     * @return {String}
     */
    get mobileNumber() {
        return this._mobileNumber;
    }

    /**
     * Setter for Mobile Number
     * @param {String} mobileNumber
     * @private
     */
    _setMobileNumber(mobileNumber) {
        this._mobileNumber = mobileNumber;
    }

    getExtraData() {
        return this._extraData;
    }

    _setExtraData(extraData) {
        this._extraData = extraData;
    }

    getSpecificExtraData(key) {
        return this._extraData[key];
    }

    setSpecificExtraData(key, data) {
        this._extraData[key] = data;
    }

    /**
     * Is this a completed User Profile?
     *
     * @returns {boolean}
     */
    get isCompleted() {
        return this.email !== null && this.firstName !== null && this.gender !== null && this.dateOfBirth !== null;
    }

    /**
     * Check if user profile is completed
     * @param {{ email: string, firstName: string, lastName: string, gender: string, dateOfBirth: string, mobileNumber: string }} param
     * @return {boolean}
     */
    static isProfileComplete({ email, firstName, lastName, gender, dateOfBirth, mobileNumber }) {
        const val = values({ email, firstName, lastName, gender, dateOfBirth, mobileNumber });
        return val.reduce((acc, val) => acc && !!val, true);
    }
}
