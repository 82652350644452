import { sortBy, isEmpty } from "lodash";
import { QuestionCodes, SurveyCodes } from "../UserJourney/UserJourneyMap";

class SurveyResults {
    constructor() {
        this.results = {};
        this.results[SurveyCodes.bloodPressure] = [];
        this.results[SurveyCodes.bodyComposition] = [];
        this.results[SurveyCodes.bodyMassIndex] = [];
    }

    validateSurveyCode(surveyCode) {
        const codeExists = Object.values(SurveyCodes).includes(surveyCode);
        if (codeExists) return true;
        throw new Error("Invalid Survey Code!");
    }

    getResultsForSurvey(surveyCode) {
        if (this.results[surveyCode]) return this.results[surveyCode];
        return [];
    }

    addResults(surveyCode, resultData) {
        if (!this.results[surveyCode]) this.results[surveyCode] = [];
        const order = this.results[surveyCode].length + 1;
        this.results[surveyCode].push({ ...resultData, order });
    }

    getFinalResultForSurvey(surveyCode) {
        const results = this.getResultsForSurvey(surveyCode);
        if (isEmpty(results)) return null;
        return this.getFinalResultFromResults(results);
    }

    getFinalResultFromResults(results = []) {
        return sortBy(results, ["order"]).pop();
    }

    get isBloodPressureRetestRatingDifferent() {
        const initialBpTestResult = this.getFinalResultFromResults(
            this.getResultsForSurvey(SurveyCodes.bloodPressure).filter(
                result => !result[QuestionCodes.bloodPressureRetest]
            )
        );

        const bpReTestResult = this.getFinalResultFromResults(
            this.getResultsForSurvey(SurveyCodes.bloodPressure).filter(
                result => result[QuestionCodes.bloodPressureRetest]
            )
        );

        return (
            initialBpTestResult?.[QuestionCodes.bloodPressureRating] !=
            bpReTestResult?.[QuestionCodes.bloodPressureRating]
        );
    }
}

export default SurveyResults;
