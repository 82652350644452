import BaseEndPoint from "../BaseEndPoint";

export default class Auth extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/auth" + path);
    }

    /**
     * Performs a login action for a given context
     *
     * @param {String} username
     * @param {String} password
     * @param {Number} expiresIn
     * @param {String} context
     * @return {Promise<Response>}
     */
    login(username, password, expiresIn, context) {
        return this.post("", { username, password, expiresIn, context }, true);
    }

    status() {
        return this.getResource("");
    }

    peep(username, registrationToken = null, setupToken = null) {
        return this.post("/peep", { username, registrationToken, setupToken }, true);
    }

    setup(token, password, confirmPassword) {
        return this.post("/setup", { token, password, confirmPassword }, true);
    }

    forgotPassword(username, context) {
        return this.post("/forgot-password", { username, context });
    }

    getUserProfile() {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("/user-profile"), settings);
    }

    /**
     * Updates the user's profile with provided information
     *
     * @param {Object} userProfile
     * @param {String} userProfile.email
     * @param {String} userProfile.firstName
     * @param {String} userProfile.lastName
     * @param {String} userProfile.dateOfBirth
     * @param {String} userProfile.gender
     * @param {String} userProfile.countryCode
     * @param {String} userProfile.postalCode
     * @param {String} userProfile.mobileNumber
     * @return {Promise<Response>}
     */
    updateUserProfile(userProfile) {
        return this.patch("/user-profile", userProfile);
    }

    requestImproveUrl(wants = "") {
        return this.post("/improve", { wants });
    }

    refresh() {
        return this.post("/refresh");
    }

    changePassword(existingPassword, newPassword, newPasswordConfirm) {
        return this.post("/change-password", {
            existingPassword,
            newPassword,
            newPasswordConfirm
        });
    }

    /**
     * Retrieves the Client Address associated to the currently logged in user, if exists
     *
     * @return {Promise<Response>}
     */
    getClientAddress() {
        return this.getResource("/client-address");
    }

    /**
     * Create User account
     * @param {String} origin
     * @param {Object} userProfile
     * @param {String} userProfile.email
     * @param {String} userProfile.firstName
     * @param {String} userProfile.lastName
     * @param {String} userProfile.dateOfBirth
     * @param {String} userProfile.gender
     * @param {String} userProfile.countryCode
     * @param {String} userProfile.postalCode
     * @param {String} userProfile.mobileNumber
     * @param {String} userProfile.password
     * @return {Promise<Response>}
     */
    signup(origin, userProfile) {
        return this.post("/signup/" + origin, userProfile, true);
    }

    sendEmailMagicLink(email, connectionId) {
        return this.post("/request-magic-link", { username: email, connectionId, context: "station" }, true, false);
    }
}
