import { UserJourney } from "../UserJourney";
import Survey from "../Survey";
import Answer from "../Survey/Answer";
import { HealthRiskAssessmentConfiguration, HealthStationConfiguration } from "./Configuration";
import BaseConfiguration from "./Configuration/BaseConfiguration";
import Consent from "../Consent/Consent";
import moment from "moment";
import DiagnosticsData from "../diagnosticsData/diagnosticsData";
import { QuestionCodes, Answers } from "../UserJourney/UserJourneyMap";
import DateConverter from "../Converters/DateConverter";
import flatMap from "lodash/flatMap";
import SurveyResults from "./SurveyResults";

export const APP_FLOWS = {
    SCRIPTS_NOW: "SCRIPTS_NOW"
};

export default class Response {
    constructor(type = Response.TYPE_STANDARD) {
        this._survey = null;

        /** @type {../UserJourney} */
        this._userJourney = null;
        this._type = type;
        this._userUUID = null;
        this._previousAnswers = {};
        this._healthCheckExtra = null;
        this._finished = false;
        this._sequence = 1;
        this._responseId = null;
        this._recommendations = {};
        this._isTest = false;
        this._configuration = null;
        this._trackedConsents = [];
        this._events = [];
        this._createdAt = null;
        this._order = null;
        this._isDod = false;
        this._dodMedicationOrders = null;
        this._dodPharmacyRecord = null;
        this._dodDefaultPharmacyRecord = null;
        this._diagnosticsData = null;
        this._appVersion = null;
        this._printOnly = false;
        this._scriptsNowOrder = null;
        this._appFlow = null;
        this._surveyResults = new SurveyResults();
    }

    static get Events() {
        return {
            AnsweredQuestion: "AnsweredQuestion",
            StartedHealthCheck: "StartedHealthCheck",
            FinishedHealthCheck: "FinishedHealthCheck",
            SkippedLogin: "SkippedLogin",
            StartedDodOrder: "StartedDodOrder",
            FinishedDodOrder: "FinishedDodOrder",
            SurveyStarted: "SurveyStarted",
            SurveyFinished: "SurveyFinished",
            DodSearchMedication: "DodSearchMedication",
            DodMedicationSelectionChanged: "DodMedicationSelectionChanged",
            DodOrderSummary: "DodOrderSummary",
            DodOrderQuantityChanged: "DodOrderQuantityChanged",
            DodPharmacyLocation: "DodPharmacyLocation",
            DodSearchPharmacy: "DodSearchPharmacy",
            DodPharmacyChanged: "DodPharmacyChanged",
            DodPharmacyLocationNext: "DodPharmacyLocationNext",
            ScriptsNowStart: "ScriptsNowStart",
            ScriptsNowOnBoarding: "ScriptsNowOnBoarding",
            ScriptsNowCancelOnboarding: "ScriptsNowCancelOnboarding",
            ScriptsNowContinueOnboarding: "ScriptsNowContinueOnboarding",
            ScriptsNowSearchMedication: "ScriptsNowSearchMedication",
            ScriptsNowSearchMedicationTriggered: "ScriptsNowSearchMedicationTriggered",
            ScriptsNowMedicationSelectionChanged: "ScriptsNowMedicationSelectionChanged",
            ScriptsNowOrderSummary: "ScriptsNowOrderSummary",
            ScriptsNowOrderQuantityChanged: "ScriptsNowOrderQuantityChanged",
            ScriptsNowPharmacyLocation: "ScriptsNowPharmacyLocation",
            ScriptsNowPharmacyLocationNext: "ScriptsNowPharmacyLocationNext",
            ScriptsNowFinishJourney: "ScriptsNowFinishJourney",
            ScriptsNowPaymentSummaryPlaceOrder: "ScriptsNowPaymentSummaryPlaceOrder",
            ScriptsNowPaymentSummaryCancelOrder: "ScriptsNowPaymentSummaryCancelOrder",
            ScriptsNowPaymentStateChanged: "ScriptsNowPaymentStateChanged",
            ScriptsNowPaymentButtonClicked: "ScriptsNowPaymentButtonClicked"
        };
    }

    static get TYPE_STANDARD() {
        return "standard";
    }

    static get TYPE_HEALTH_CHECK() {
        return "healthCheck";
    }

    static get TYPE_HEALTH_RISK_ASSESSMENT() {
        return "healthRiskAssessment";
    }

    /**
     * A factory helper for preparing the response for HealthCheck contexts
     *
     * @returns {Response}
     */
    static createForHealthCheck(configuration = {}) {
        let response = new Response(this.TYPE_HEALTH_CHECK);
        response.configuration = new HealthStationConfiguration(configuration);
        return response;
    }

    /**
     * A factory helper for preparing the response for Health Risk Assessment contexts
     *
     * @returns {Response}
     */
    static createForHealthRiskAssessment(configuration) {
        let response = new Response(this.TYPE_HEALTH_RISK_ASSESSMENT);
        response.configuration = new HealthRiskAssessmentConfiguration(configuration);
        return response;
    }

    /**
     * Set an object that uses question codes as the keys with an array of answers
     * to use as a basis for pre-populating questions.
     *
     * Converts the plain-text answers into Answer objects to match the way that Questions manage/add answers
     *
     * @param {Object} answers
     */
    setPreviousAnswers(answers) {
        let previousAnswers = {};

        for (let questionCode in answers) {
            let questionAnswers = answers[questionCode];
            if (questionAnswers.length) {
                previousAnswers[questionCode] = [];
                for (let answer of questionAnswers) {
                    previousAnswers[questionCode].push(new Answer(answer));
                }
            }
        }

        this._previousAnswers = previousAnswers;
    }

    /**
     *
     * @param id
     * @return {Response}
     */
    set userUUID(id) {
        this._userUUID = id;
        return this;
    }

    /**
     *
     * @return {string|null}
     */
    get userUUID() {
        return this._userUUID;
    }

    /**
     * @returns {string|null}
     */
    get userEmail() {
        if (
            this._healthCheckExtra &&
            this._healthCheckExtra.hasOwnProperty("user") &&
            this._healthCheckExtra.user &&
            this._healthCheckExtra.user.hasOwnProperty("emailAddress")
        ) {
            return this._healthCheckExtra.user.emailAddress;
        } else {
            return null;
        }
    }

    /**
     *
     * @return {boolean}
     */
    isTypeStandard() {
        return this._type === Response.TYPE_STANDARD;
    }

    /**
     *
     * @return {boolean}
     */
    isTypeHealthCheck() {
        return this._type === Response.TYPE_HEALTH_CHECK;
    }

    /**
     *
     * @return {boolean}
     */
    get isPrintOnlyHealthCheck() {
        return this.isTypeHealthCheck() && this.printOnly;
    }

    /**
     *
     * @return {boolean}
     */
    isTypeHealthRiskAssessment() {
        return this._type === Response.TYPE_HEALTH_RISK_ASSESSMENT;
    }

    /**
     *
     * @param {UserJourney} userJourney
     * @returns {Response}
     */
    setUserJourney(userJourney) {
        this._userJourney = userJourney;
        this._userJourney.setResponse(this);
        return this;
    }

    /**
     *
     * @returns {UserJourney}
     */
    getUserJourney() {
        return this._userJourney;
    }

    /**
     * Set Survey
     *
     * @param survey
     * @return {Response}
     */
    setSurvey(survey) {
        this._survey = survey;
        this._survey.setResponse(this);
        return this;
    }

    /**
     *
     * @return {Survey}
     */
    getSurvey() {
        return this._survey;
    }

    /**
     * Returns an object of answers with question codes as the Object key.
     * Answers to Questions without codes are not returned
     *
     * @returns {Object}
     */
    getAnswersForPreFill() {
        let answers = {};

        if (this._userJourney) {
            let userJourneyPages = this._userJourney.getUserJourneyPages();

            for (let ujp = 0; ujp < userJourneyPages.length; ujp++) {
                let userJourneyPage = userJourneyPages[ujp];

                switch (true) {
                    case userJourneyPage.getPageType().isTypeSurvey():
                        answers = Object.assign(
                            answers,
                            this._extractAnswersFromSurveyForQuestionsWithACode(userJourneyPage.getSurvey())
                        );
                        break;

                    /* istanbul ignore next */ case userJourneyPage.getPageType().isTypeConsent():
                        if (userJourneyPage.getConsent().getSurvey()) {
                            answers = Object.assign(
                                answers,
                                this._extractAnswersFromSurveyForQuestionsWithACode(
                                    userJourneyPage.getConsent().getSurvey()
                                )
                            );
                        }
                        break;
                }
            }

            // FIXME PLZ
            // When recompositing on the API side, the consent resource is different whereas on the frontend it's the same resource.
            // When we move answers to the Response object (SU-578), the problem should go away.
            /* istanbul ignore next */
            for (let trackedConsent of this.getTrackedConsents()) {
                if (trackedConsent.getSurvey()) {
                    answers = Object.assign(
                        answers,
                        this._extractAnswersFromSurveyForQuestionsWithACode(trackedConsent.getSurvey())
                    );
                }
            }
        } else if (this._survey) {
            answers = this._extractAnswersFromSurveyForQuestionsWithACode(this._survey);
        }

        // use any previous answers first. Survey Question answers are considered more up to date therefore will override
        // any defined previous answer
        answers = { ...this._previousAnswers, ...answers };

        // calculate and append/overwrite any derived answers
        answers = { ...answers, ...this.generateDerivedAnswers(answers) };

        return answers;
    }

    /**
     * Generates derived answers for a specific set of aggregate question codes (eg. age)
     * @return {Object.<String, [Answer]>}
     */
    generateDerivedAnswers(answers) {
        const derivedAnswers = {};

        // generate Age
        const dateOfBirthAnswer = answers[QuestionCodes.dateOfBirth];
        if (dateOfBirthAnswer && dateOfBirthAnswer.length > 0) {
            const fromDate = moment(dateOfBirthAnswer[0].answer, "YYYY-MM-DD", true);
            const age = DateConverter.calculateYearsDifference(fromDate, moment());

            /* istanbul ignore else */
            if (!isNaN(age)) {
                derivedAnswers[QuestionCodes.age] = [new Answer(age)];
            }
        }

        // Re-populate IS_SMOKER question
        const doYouSmoke = answers[QuestionCodes.doYouSmokeExcludingVaping]?.[0]?.answer;
        if (doYouSmoke) {
            derivedAnswers[QuestionCodes.isSmoker] = [
                new Answer(doYouSmoke == Answers.SmokingDoYouSmokeNew.yes ? Answers.YesNo.yes : Answers.YesNo.no)
            ];
        }

        return derivedAnswers;
    }

    /**
     * Returns if any Survey associated to this Response contains Questions that contain PII and have been answered
     *
     * @returns {boolean}
     */
    hasAnswersContainingPII() {
        let hasAnswersContainingPII = false;
        let consentSurvey;

        if (this._userJourney) {
            for (let userJourneyPage of this._userJourney.getUserJourneyPages()) {
                switch (true) {
                    case userJourneyPage.getPageType().isTypeSurvey(): // FIXME: suppress coverage
                        /* istanbul ignore else */ if (!hasAnswersContainingPII && userJourneyPage.getSurvey()) {
                            hasAnswersContainingPII = this._surveyHasAnswersContainingPII(userJourneyPage.getSurvey());
                        }
                        break;

                    /* istanbul ignore next */ case userJourneyPage.getPageType().isTypeConsent():
                        consentSurvey = userJourneyPage.getConsent().getSurvey();
                        if (!hasAnswersContainingPII && consentSurvey) {
                            hasAnswersContainingPII = this._surveyHasAnswersContainingPII(consentSurvey);
                        }
                        break;
                }
            }
        } else {
            hasAnswersContainingPII = this._surveyHasAnswersContainingPII(this._survey);
        }

        return hasAnswersContainingPII;
    }

    /**
     *
     * @param {Survey} survey
     * @private
     */
    _surveyHasAnswersContainingPII(survey) {
        let hasAnswersContainingPII = false;

        for (let questionGroup of survey.getQuestionGroups()) {
            for (let question of questionGroup.getQuestions()) {
                if (!hasAnswersContainingPII && question.getAnswersContainPII() && question.getAnswers().length > 0) {
                    hasAnswersContainingPII = true;
                }
            }
        }

        return hasAnswersContainingPII;
    }

    /**
     * Returns an object of answers for the given Survey where the answered question must have a question code
     *
     * @param {Survey} survey
     * @private
     */
    _extractAnswersFromSurveyForQuestionsWithACode(survey) {
        let answers = {};
        for (let group of survey.getQuestionGroups()) {
            for (let question of group.getQuestions()) {
                if (
                    question.getCode() &&
                    (question.isStateAnswered() || question.isStateAnsweredByPreFill()) &&
                    !answers.hasOwnProperty(question.getCode())
                ) {
                    answers[question.getCode()] = question.getAnswers();
                }
            }
        }

        return answers;
    }

    /**
     * Convert this Response instance into a POJO
     */
    toJSON() {
        let trackedConsents = this._trackedConsents.map(consent => consent.toJSON());

        return {
            finished: this.finished,
            sequence: this.sequence,
            responseId: this._responseId,
            userUUID: this._userUUID,
            type: this._type,
            survey: this._survey ? this._survey.toJSON() : null,
            userJourney: this._userJourney ? this._userJourney.toJSON() : null,
            healthCheckExtra: this._healthCheckExtra,
            recommendations: this._recommendations,
            isTest: this.isTest,
            configuration: this._configuration ? this._configuration.raw : {},
            trackedConsents,
            events: this._events,
            createdAt: this._createdAt,
            appVersion: this._appVersion,
            order: this._order,
            isDod: this._isDod,
            diagnosticsData: this._diagnosticsData ? this._diagnosticsData.toJson() : null,
            printOnly: this.printOnly,
            scriptsNowOrder: this._scriptsNowOrder,
            appFlow: this._appFlow,
            surveyResults: this._surveyResults.results
        };
    }

    /**
     * Designed for use server side where a persisted response JSON is used to recreate the Response object state prior to submission.
     * Hydration should not trigger any Response, User Journey or Survey model internal state adaptations.
     *
     * @param data
     * @return {Response}
     */
    fromJSON(data) {
        this._responseId = data.responseId;
        this._type = data.type;
        this._userUUID = data.userUUID;
        this._finished = data.finished;
        this._sequence = data.sequence;
        this._recommendations = data.recommendations ? data.recommendations : {};
        this.isTest = data.isTest;
        this._healthCheckExtra = data.healthCheckExtra;
        this._events = data.events ? data.events : [];
        this._createdAt = data.createdAt;
        this._appVersion = data.appVersion;
        this._order = data.hasOwnProperty("order") ? data.order : [];
        this._isDod = data.hasOwnProperty("isDod") ? data.isDod : false;
        this._printOnly = data.printOnly || false;

        // replace with configuration objects
        if (this.isTypeHealthCheck()) {
            this.configuration = new HealthStationConfiguration(data.configuration);
        } else if (this.isTypeHealthRiskAssessment()) {
            this.configuration = new HealthRiskAssessmentConfiguration(data.configuration);
        }

        this._trackedConsents = [];
        if (data.hasOwnProperty("trackedConsents")) {
            this._trackedConsents = data.trackedConsents.map(consent => {
                let c = new Consent(consent.schema);
                c.fromJSON(consent, this);
                return c;
            });
        }

        if (data.userJourney) {
            let userJourney = new UserJourney(data.userJourney.schema);
            this.setUserJourney(userJourney);

            userJourney.getUserJourneyPages().map((userJourneyPage, userJourneyPageIndex) => {
                userJourneyPage.setResolved(data.userJourney.userJourneyPages[userJourneyPageIndex].resolved);
                userJourneyPage.setPreConditionEvaluationResult(
                    data.userJourney.userJourneyPages[userJourneyPageIndex].preConditionEvaluationResult
                );

                let survey = userJourneyPage.getSurvey();
                if (survey) {
                    let json = data.userJourney.userJourneyPages[userJourneyPageIndex].survey;

                    this.hydrateSurvey(survey, json);
                }
            });
        } else if (data.survey) {
            let survey = new Survey(data.survey.schema);
            this.setSurvey(survey);

            this.hydrateSurvey(survey, data.survey);
        }

        this._diagnosticsData = data.diagnosticsData == null ? null : new DiagnosticsData(data.diagnosticsData);
        this._scriptsNowOrder = data.scriptsNowOrder;
        this._appFlow = data.appFlow;
        return this;
    }

    /**
     * Hydrate a survey with the given json
     *
     * @param survey
     * @param json
     */
    hydrateSurvey(survey, json) {
        survey.getQuestionGroups().map((questionGroup, questionGroupIndex) => {
            questionGroup.getQuestions().map((question, questionIndex) => {
                let questionJSON = json.questionGroups[questionGroupIndex].questions[questionIndex];
                question.fromJSON(questionJSON);
            });
        });

        survey.setState(json.state);
        survey.setCompleteState(json.completeState);
    }

    /**
     * Return answers for the question with the given code
     *
     * @param code
     * @return {Array}
     */
    getAnswersForQuestionByCode(code) {
        let answers = null;
        let answersForQuestionsWithCodes = this.getAnswersForPreFill();

        if (answersForQuestionsWithCodes.hasOwnProperty(code)) {
            answers = answersForQuestionsWithCodes[code];
        }

        return answers;
    }

    /**
     * Returns all answers with the question Id as the key
     *
     * @return {Object}
     */
    getAnswers() {
        let answers = {};

        // not doing this ignore means that the else block is expected.
        /* istanbul ignore else */
        if (this._userJourney) {
            for (let userJourneyPage of this._userJourney.getUserJourneyPages()) {
                let survey = userJourneyPage.getSurvey();
                if (survey) {
                    answers = Object.assign({}, answers, this._getAnswersFromSurvey(survey));
                }
            }

            // FIXME PLZ
            // When recompositing on the API side, the consent resource is different whereas on the frontend it's the same resource.
            // When we move answers to the Response object (SU-578), the problem should go away.
            /* istanbul ignore next */
            for (let trackedConsent of this.getTrackedConsents()) {
                if (trackedConsent.getSurvey()) {
                    answers = Object.assign(answers, this._getAnswersFromSurvey(trackedConsent.getSurvey()));
                }
            }
        } else if (this._survey) {
            answers = this._getAnswersFromSurvey(this._survey);
        }

        return answers;
    }

    /**
     * Returns object with questionId as the key with the value being another object containing the question code and answers
     *
     * @param survey
     * @private
     */
    _getAnswersFromSurvey(survey) {
        let questionAnswers = {};
        /** @type {../Survey/QuestionGroup[]} */
        for (let group of survey.getQuestionGroups()) {
            /** @type {../Survey/Question[]} */
            let questions = group.getQuestions();
            for (let question of questions) {
                if (question.hasBeenResolved()) {
                    questionAnswers[question.getQuestionId()] = {
                        questionCode: question.getCode(),
                        displayText: question.getDisplayText(),
                        answers: question.getAnswers()
                    };
                }
            }
        }

        return questionAnswers;
    }

    getAnsweredQuestions() {
        let answeredQuestions = [];
        let surveyQuestions = [];
        let consentQuestions = [];
        if (this._userJourney) {
            let pages = this._userJourney.getUserJourneyPages();
            if (pages && pages.length > 0) {
                pages = pages.filter(page => page.getSurvey() != null);
                surveyQuestions = flatMap(pages, page => page.getSurvey().getAnsweredQuestion());
            }

            // this is just bad
            let trackedConsents = this.getTrackedConsents();
            if (trackedConsents && trackedConsents.length > 0) {
                trackedConsents = trackedConsents.filter(tc => tc.getSurvey() != null);
                consentQuestions = flatMap(trackedConsents, tc => tc.getSurvey().getAnsweredQuestion());
            }

            answeredQuestions = [...surveyQuestions, ...consentQuestions];
        } else if (this._survey) {
            answeredQuestions = this._survey.getAnsweredQuestion();
        }

        return answeredQuestions;
    }

    hasEvent(eventType) {
        const result = this.events.find(event => event.type === eventType) || null;
        return !!result;
    }

    /**
     * Gets health-check extra data
     * @return {Object|null}
     */
    getHealthCheckExtra() {
        return this._healthCheckExtra;
    }

    /**
     * Sets the health-check extra data.
     * @param healthCheckExtra
     */
    setHealthCheckExtra(healthCheckExtra) {
        this._healthCheckExtra = healthCheckExtra;
        return this;
    }

    get finished() {
        return this._finished;
    }

    setIsFinished() {
        return (this._finished = true);
    }

    incrementSequence() {
        this._sequence++;
    }

    get sequence() {
        return this._sequence;
    }

    get responseId() {
        return this._responseId;
    }

    set responseId(id) {
        this._responseId = id;
    }

    get recommendations() {
        return this._recommendations;
    }

    /**
     * Stores a recommendation, replacing any existing recommendation for that ad slot
     * @param recommendation
     */
    storeRecommendation(recommendation) {
        this._recommendations[recommendation.adSlotId] = recommendation;
    }

    set isTest(value) {
        this._isTest = value;
    }

    get isTest() {
        return this._isTest;
    }

    get campaignId() {
        return this._configuration.campaignId;
    }

    /**
     *
     * @param {BaseConfiguration} config
     */
    set configuration(config) {
        if (!(config instanceof BaseConfiguration)) {
            throw new Error("Configuration must be an instance of BaseConfiguration");
        }

        this._configuration = config;
    }

    /**
     *
     * @returns {BaseConfiguration|null}
     */
    get configuration() {
        return this._configuration;
    }

    /**
     *
     * @returns {[{Object}]}
     */
    getTrackedConsents() {
        return this._trackedConsents;
    }

    trackConsent(consent) {
        let isTracked = false;
        for (let existingConsent of this._trackedConsents) {
            if (existingConsent.consentId === consent.consentId) {
                isTracked = true;
            }
        }

        if (!isTracked) {
            this._trackedConsents.push(consent);
        }
    }

    /**
     * Adds an event of a certain type with optional data
     *
     * @param {string} type
     * @param {object} data
     */
    addEvent(type, data = {}) {
        this._events.push({
            timestamp: moment.utc().toISOString(),
            type,
            data
        });
    }

    /**
     *
     * @returns {Array}
     */
    get events() {
        return this._events;
    }

    /**
     *
     * @returns {Array}
     */
    get surveyResults() {
        return this._surveyResults;
    }

    /**
     * @returns {string}
     */
    get createdAt() {
        return this._createdAt;
    }

    /**
     * Gets the app version
     * @returns {string}
     */
    get appVersion() {
        return this._appVersion;
    }

    /**
     * Set the user's order (things they want to purchase on DOD journey) not what position they are in
     *
     * @param order
     */
    set order(order) {
        this._order = order;
    }

    /**
     * Gets the user's order (things they want to purchase on DOD journey) not what position they are in
     * @returns {null}
     */
    get order() {
        return this._order;
    }

    /**
     * Sets whether this response is associated with a DOD journey
     * @param value
     */
    set isDod(value) {
        this._isDod = value;
    }

    /**
     * Gets whether this response is associated with a DOD journey
     * @returns {boolean}
     */
    get isDod() {
        return this._isDod;
    }

    set printOnly(value) {
        this._printOnly = value;
    }

    get printOnly() {
        return this._printOnly;
    }

    /**
     * Data containers, not hydrated to the final response
     */

    set dodMedicationOrders(order) {
        this._dodMedicationOrders = order;
    }

    get dodMedicationOrders() {
        return this._dodMedicationOrders;
    }

    set dodPharmacyRecord(record) {
        this._dodPharmacyRecord = record;
    }

    get dodPharmacyRecord() {
        return this._dodPharmacyRecord;
    }

    set dodDefaultPharmacyRecord(record) {
        this._dodDefaultPharmacyRecord = record;
    }

    get dodDefaultPharmacyRecord() {
        return this._dodDefaultPharmacyRecord;
    }

    set diagnosticsData(value) {
        this._diagnosticsData = value;
    }

    get diagnosticsData() {
        return this._diagnosticsData;
    }

    set scriptsNowOrder(value) {
        this._scriptsNowOrder = value;
    }

    get scriptsNowOrder() {
        return this._scriptsNowOrder;
    }

    set appFlow(value) {
        this._appFlow = value;
    }

    get appFlow() {
        return this._appFlow;
    }

    isAppFlowScriptsNow() {
        return this._appFlow === APP_FLOWS.SCRIPTS_NOW;
    }
}
