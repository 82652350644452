import Response from "./Response";
import zlib from "pako";
import axios from "axios";

const NO_COMPRESS_HTTP_METHODS = ["HEAD", "GET", "DELETE"];

const Constants = {
    buildVersionTag: "X-SISU-BUILD-VERSION",
    releaseVersionTag: "X-SISU-RELEASE-VERSION",
    originHeader: "X-SISU-ORIGIN",
    clientTypeHeader: "X-SISU-CLIENT-TYPE"
};

export default class BaseEndPoint {
    constructor(client) {
        this.client = client;
    }

    /**
     * Returns an object containing fetch() settings
     * @returns {{modes: "cors", headers: {"Content-Type": string,  Accept: "application/json"}}} Default request settings object
     */
    _getDefaultFetchSettings() {
        let headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
        };

        const { buildVersion, releaseVersion, disablePragma, origin, clientType, ...rest } = this.client.config;

        if (buildVersion) {
            headers[Constants.buildVersionTag] = buildVersion;
        }

        if (releaseVersion) {
            headers[Constants.releaseVersionTag] = releaseVersion;
        }

        if (disablePragma) {
            delete headers["Pragma"];
        }

        if (origin) {
            headers[Constants.originHeader] = origin;
        }

        if (clientType) {
            headers[Constants.clientTypeHeader] = clientType;
        }

        return {
            mode: "cors",
            headers,
            ...rest
        };
    }

    /**
     * Given an object of keys/values, generate a query string
     *
     * @param {Object<string, any>} params
     * @returns {string}
     */
    generateQueryString(params) {
        if (params && Object.keys(params).length) {
            const query = new URLSearchParams();

            for (let key in params) {
                const value = params[key];
                query.append(key, value);
            }

            return "?" + query.toString();
        } else {
            return "";
        }
    }

    /**
     * Returns the domain and full path to the resource endpoint
     * @param {string} path - URL path of resource endpoint
     */
    getUrlPath(path) {
        return this.client.config.domain + path;
    }

    /**
     * Returns a list of resources
     * @param {boolean} detailed
     * @param {Object} queryStringParameters
     * @return {Promise<Response>}
     */
    get(detailed = false, queryStringParameters = null) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        const queryString = this.generateQueryString(queryStringParameters);
        return this._send(this.getUrlPath(detailed ? "detailed" : "") + queryString, settings);
    }

    /**
     * Returns the requested resource
     *
     * @param resourceId
     * @param detailed
     * @return {Promise<Response>}
     */
    getResource(resourceId, detailed = false) {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath(resourceId + (detailed ? "/detailed" : "")), settings);
    }

    /**
     * Creates a new resource
     *
     * @param {string} url
     * @param {Object<string, any>} data
     * @param {boolean} publicRoute
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     */
    post(url, data, publicRoute = false, compress = true) {
        let settings = this._getDefaultFetchSettings();

        settings = Object.assign(settings, {
            method: "POST",
            data: JSON.stringify(data)
        });

        return this._send(this.getUrlPath(url), settings, publicRoute, compress);
    }

    /**
     * Creates a new resource
     *
     * @param {string} url
     * @param {Object<string, any>} data
     * @param {boolean} publicRoute
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     */
    patch(url, data, publicRoute = false, compress = true) {
        let settings = this._getDefaultFetchSettings();

        settings = Object.assign(settings, {
            method: "PATCH",
            data: JSON.stringify(data)
        });

        return this._send(this.getUrlPath(url), settings, publicRoute, compress);
    }

    /**
     * Update a resource
     *
     * @param {string} url
     * @param {Object<string, any>} data
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     */
    put(url, data, compress = true) {
        let settings = this._getDefaultFetchSettings();

        settings = Object.assign(settings, {
            method: "PUT",
            data: JSON.stringify(data)
        });

        return this._send(this.getUrlPath(url), settings, false, compress);
    }

    /**
     * Delete a resource
     *
     * @param {string} url
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     */
    delete(url, compress = true) {
        let settings = this._getDefaultFetchSettings();

        settings = Object.assign(settings, {
            method: "DELETE"
        });

        return this._send(this.getUrlPath(url), settings, false, compress);
    }

    /**
     *
     * @param {any} data
     * @returns {Promise}
     * @private
     */
    _compressData(data) {
        return new Promise(function(resolve, reject) {
            try {
                if (data) {
                    const buffer = zlib.deflate(data);
                    resolve(buffer);
                } else {
                    resolve();
                }
            } catch (error) {
                console.error("Failed to deflate with zlib", error);
                reject(error);
            }
        });
    }

    /**
     * Sends the request and returns a Response object
     *
     * @param {string} route
     * @param {Object<string, any>} settings
     * @param {boolean} publicRoute
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     * @protected
     */
    _send(route, settings = {}, publicRoute = false, compress = true) {
        if (!publicRoute) {
            settings.headers["Authorization"] = "Bearer " + this.client.authToken;
        }

        let processSettings = Promise.resolve(settings);
        if (compress && !NO_COMPRESS_HTTP_METHODS.includes(settings.method)) {
            processSettings = this._compressData(settings.data)
                .then(compressedBody => {
                    settings.headers["Content-Encoding"] = "deflate";
                    settings.data = compressedBody;
                    return settings;
                })
                .catch(error => {
                    throw error;
                });
        }

        return processSettings.then(processedSettings =>
            // eslint-disable-next-line no-undef
            axios({
                ...processedSettings,
                url: route,
                validateStatus: status => typeof status == "number"
            })
                .then(response => new Response(response))
                .catch(err => new Response({ status: 0, data: err }))
        );
    }
}
