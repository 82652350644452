import React from "react";
import { Text } from "rebass";
import { NormalLabel } from "@sisuwellness/ui/src/components/Labels";

// Errors
export const REQUEST_FAILED_ERROR_MESSAGE =
    "Sorry, an unexpected error occurred. Please retry, email SiSU Support if the problem persists via feedback@sisuhealthgroup.com";
export const NO_NETWORK_ERROR_MESSAGE =
    "It appears that you are not connected to Internet. Please retry or check your network connection.";
export const INVALID_EMAIL_ADDRESS_ERROR_MESSAGE = "Please enter a valid email address e.g. abc@abc.com";
export const INVALID_REG_LINK_ERROR_MESSAGE = "This registration link is invalid or has expired.";
export const NOT_REGISTERED_EMAIL_ERROR_MESSAGE = "This email is not registered. Please Sign Up to create an account.";
export const INCORRECT_PASSWORD_ERROR_MESSAGE = "Your existing password was incorrect. Please try again.";
export const START_END_DATE_REQUIRED_ERROR_MESSAGE = "Please enter a start and end date for your report.";
export const EXPIRED_SESSION_ERROR_MESSAGE = "Your session has timed out, please login again.";
export const INVALID_ACCESS_ERROR_MESSAGE =
    "This account does not have access to this portal, please try another account.";

// Identify view
export const CHECK_EMAIL_ACTIVATION_LINK_MESSAGE =
    "Please check your email for an activation link in order to complete your account.";

// Login view
export const PASSWORD_RESET_SUCCESS_MESSAGE = "A forgot password link has been sent to your email address.";

// Message types
export const MESSAGE_ERROR = "error";
export const MESSAGE_GENERAL = "general";
export const MESSAGE_SUCCESS = "success";

export const LOADING = {
    MESSAGE: "Loading...",
    WAITING: "Please wait while we crunch your data"
};

export const ERROR_MESSAGES = {
    VALIDATION: {
        MOBILE_NUMBER: {
            AUSTRALIA: "Please provide a valid Australian mobile number (04XXXXXXXX).",
            UNITED_KINGDOM: "Please provide a valid UK mobile number (07XXXXXXXXX)."
        }
    }
};

export const HEART_AGE = {
    MODAL: {
        CALCULATE: {
            HEADING: "How are your results calculated?",
            CONTENT: (
                <>
                    <NormalLabel fontSize="18px" fontWeight="300" color="blackTints700" mb="1em">
                        Results are based on the Framingham Risk Tool, developed using data from the Framingham
                        institute’s ongoing Heart Study. The Framingham Heart Study (FHS) began in 1948, and the
                        monitoring of the study has since led to the identification of major cardiovascular disease
                        (CVD) risk factors.
                    </NormalLabel>
                    <NormalLabel fontSize="18px" fontWeight="300" color="blackTints700">
                        The Framingham Risk Tool uses these major risk factors to determine Heart Age.
                    </NormalLabel>
                </>
            )
        },
        HEART_AGE_INFO: {
            HEADING: "What is Heart Age?",
            CONTENT: (
                <>
                    <Text mb="1em">
                        Heart Age is a way to understand your risk of a heart attack or stroke. Your Heart Age is
                        calculated based on your risk factors for heart disease, which include metrics such as age,
                        gender, blood pressure, smoking and diabetes.
                    </Text>
                    <Text mb="1em">
                        If your Heart Age is older than your current age, this means you have an elevated risk of heart
                        disease compared to others your age. Conversely, a younger Heart Age means you have a lower risk
                        of heart disease compared to others your age.
                    </Text>
                    <Text mb="1em">
                        The calculation was developed and based upon data from the Framingham Heart Study
                        <Text as="sup" fontSize="13px">
                            1 2 3
                        </Text>
                        .
                    </Text>
                    <Text mb="1em">
                        Heart Age is not intended to be used as a diagnostic medical device — it is important to always
                        talk with your doctor before making any changes to your lifestyle or medications.
                    </Text>
                    <Text mb="1em" fontSize="13px">
                        1. Mahmood S, Levy D, Vasan R and Wang T. 2014 March. The Framingham Heart Study and the
                        Epidemiology of cardiovascular disease: A historical perspective.
                    </Text>
                    <Text mb="1em" fontSize="13px">
                        2. Kannel, et al. 1968. The Framingham study: an epidemiological investigation of cardiovascular
                        disease. Volume 3.
                    </Text>
                    <Text fontSize="13px">
                        3. National Heart, L. a. B. I., 2014 July. The Framingham Heart Study (FHS). [Online] [Accessed
                        28 September 2017].
                    </Text>
                </>
            )
        },
        HEART_STROKE: {
            HEADING: "What is Heart and Stroke Risk?",
            CONTENT: (
                <>
                    <Text mb="1em">
                        The Heart and Stroke Risk was developed from the same large US population study (the Framingham
                        Heart Study) as Heart Age.
                    </Text>
                    <Text mb="1em">
                        It estimates the risk you have of developing cardiovascular disease (including heart disease,
                        stroke, heart failure) in the next 10 years.
                    </Text>
                    For example, if you have a risk score of 5%, that means you have a 5% (or 1 in 20) chance of
                    developing cardiovascular disease within the next 10 years.
                </>
            )
        }
    }
};
