import QuestionGroup from "./QuestionGroup";

export default class Survey {
    /**
     *
     * @param schema
     * @param userJourneyPage {UserJourneyPage}
     */
    constructor(schema, userJourneyPage = null) {
        this._schema = schema;
        this._userJourneyPage = userJourneyPage;

        this._states = {
            notStarted: "NOT_STARTED",
            inProgress: "IN_PROGRESS",
            complete: "COMPLETE"
        };

        this._completeStates = {
            questionExitCondition: "QUESTION_EXIT_CONDITION",
            answeredAllQuestions: "ANSWERED_ALL_QUESTIONS",
            userExited: "USER_EXITED",
            userSkipped: "USER_SKIPPED"
        };

        this._state = this._states.notStarted;
        this._completeState = null;
        this._response = null;
        this._healthStationMode = false;
        this._isDisabled = false;
        this._reportCallback = null;
        this._firstQuestionShown = null;

        this._schema.surveyQuestionGroups.sort(this.sortByPosition);

        // convert surveyQuestionGroups schemas into QuestionGroup models
        let previousQuestionGroup = null;
        this._questionGroups = this._schema.surveyQuestionGroups.map(groupSchema => {
            let questionGroup = new QuestionGroup(groupSchema, this);

            // FIXME: Update Survey tests to enforce coverage.. running out of time
            /* istanbul ignore next */
            if (previousQuestionGroup) {
                previousQuestionGroup.nextQuestionGroup = questionGroup;
            }

            previousQuestionGroup = questionGroup;
            return questionGroup;
        });
    }

    /**
     *
     * @param bool
     * @returns {Survey}
     */
    setHealthStationMode(bool) {
        this._healthStationMode = bool;
        return this;
    }

    /**
     *
     * @returns {boolean}
     */
    getHealthStationMode() {
        return this._healthStationMode;
    }

    /**
     *
     * @returns {UserJourneyPage}
     */
    getUserJourneyPage() {
        return this._userJourneyPage;
    }

    /**
     *
     * @param Response
     * @returns {Survey}
     */
    setResponse(Response) {
        this._response = Response;
        this.evaluateSurveyState();
        return this;
    }

    /**
     *
     * @returns {Response}
     */
    getResponse() {
        // TODO: inelegant but hey
        if (!this._response && this._userJourneyPage) {
            this._response = this._userJourneyPage.getUserJourney().getResponse();
        }

        return this._response;
    }

    /**
     *
     * @returns {QuestionGroup[]}
     */
    getQuestionGroups() {
        return this._questionGroups;
    }

    /**
     * Returns the next Question Group that is yet to be completed
     *
     * @returns {QuestionGroup}
     */
    getNextIncompleteQuestionGroup() {
        let nextIncompleteQuestionGroup = null;
        let nextQuestionGroup =
            this._questionGroups.length > 0 ? this._questionGroups[0] : /* istanbul ignore next */ null;

        while (!nextIncompleteQuestionGroup && nextQuestionGroup) {
            if (!nextQuestionGroup.isComplete()) {
                // found it... set and it will be returned
                nextIncompleteQuestionGroup = nextQuestionGroup;
            } else {
                // go forward one question group more
                nextQuestionGroup = nextQuestionGroup.nextQuestionGroup;
            }
        }

        return nextQuestionGroup;
    }

    /**
     *
     * @returns {QuestionGroup}
     */
    getQuestionGroupByQuestionGroupId(questionGroupId) {
        let questionGroup = null;

        for (let i = 0; i < this.getQuestionGroups().length; i++) {
            let searchQuestionGroup = this.getQuestionGroups()[i];
            if (searchQuestionGroup.getQuestionGroupId() === questionGroupId) {
                questionGroup = searchQuestionGroup;
                break;
            }
        }

        return questionGroup;
    }

    /**
     *
     */
    evaluateSurveyState() {
        if (this.getNextIncompleteQuestionGroup() === null) {
            this.setCompleteByAnsweredAllQuestions();

            if (this.getUserJourneyPage()) {
                this.getUserJourneyPage().setResolved(true);
            }
        } else if (!this.isStateInProgress()) {
            this.setStateInProgress();
        }
    }

    getDisplayName() {
        return this._schema.displayName;
    }

    getSurveyId() {
        return this._schema.surveyId;
    }

    getCode() {
        return this._schema.code;
    }

    getShowInHealthStationMenu() {
        return this._schema.showInHealthStationMenu;
    }

    getState() {
        return this._state;
    }

    get isDisabled() {
        return this._isDisabled;
    }

    set isDisabled(value) {
        this._isDisabled = value;
    }

    setReportCallback(callback) {
        this._reportCallback = callback;
    }

    report(status) {
        this._reportCallback ? this._reportCallback(this, status) : null;
    }

    /**
     *
     * @returns {Object}
     */
    toJSON() {
        let pojo = {
            schema: this._schema,
            state: this._state,
            completeState: this._completeState
        };

        pojo.questionGroups = this.getQuestionGroups().map(questionGroup => questionGroup.toJSON());
        return pojo;
    }

    /* istanbul ignore next */
    sortByPosition(a, b) {
        if (a.position === b.position) {
            return 0;
        } else {
            return a.position < b.position ? -1 : 1;
        }
    }

    /**
     *
     * @param state
     * @returns {Survey}
     */
    setState(state) {
        this._state = state;
        return this;
    }

    /**
     *
     * @param state
     * @returns {Survey}
     */
    setCompleteState(state) {
        this._completeState = state;
        return this;
    }

    /**
     *
     * @param {string} questionCode
     * @return {Question|null}
     */
    getQuestionByCode(questionCode) {
        let question;
        /**
         * @type {QuestionGroup} questionGroup
         */
        for (let questionGroup of this.getQuestionGroups()) {
            /** @type {Question} question */
            question = questionGroup.getQuestionByQuestionCode(questionCode);
            /* istanbul ignore else */
            if (question) {
                break;
            }
        }

        return question;
    }

    /**
     *
     * @returns {Survey}
     */
    setStateInProgress() {
        this._state = this._states.inProgress;
        return this;
    }

    /**
     *
     * @returns {Survey}
     */
    setStateComplete() {
        this._state = this._states.complete;
        return this;
    }

    /**
     *
     * @returns {Survey}
     */
    setCompleteByQuestionExitCondition() {
        this.setStateComplete();
        this._completeState = this._completeStates.questionExitCondition;
        return this;
    }

    /**
     *
     * @returns {Survey}
     */
    setCompleteByUserExited() {
        this.setStateComplete();
        this._completeState = this._completeStates.userExited;
        return this;
    }

    /**
     *
     * @returns {Survey}
     */
    setCompleteByAnsweredAllQuestions() {
        this.setStateComplete();
        this._completeState = this._completeStates.answeredAllQuestions;
        return this;
    }

    /**
     *
     * @returns {Survey}
     */
    setCompleteByUserSkipped() {
        this.setStateComplete();
        this._completeState = this._completeStates.userSkipped;
        return this;
    }

    /**
     *
     * @returns {boolean}
     */
    isStateNotStarted() {
        return this._state === this._states.notStarted;
    }

    /**
     *
     * @returns {boolean}
     */
    isStateInProgress() {
        return this._state === this._states.inProgress;
    }

    /**
     *
     * @returns {boolean}
     */
    isStateComplete() {
        return this._state === this._states.complete;
    }

    /**
     *
     * @returns {boolean}
     */
    isCompleteStateQuestionExitCondition() {
        return this._completeState === this._completeStates.questionExitCondition;
    }

    /**
     *
     * @returns {boolean}
     */
    isCompleteStateUserExited() {
        return this._completeState === this._completeStates.userExited;
    }

    /**
     *
     * @returns {boolean}
     */
    isCompleteStateUserSkipped() {
        return this._completeState === this._completeStates.userSkipped;
    }

    /**
     *
     * @returns {boolean}
     */
    isCompleteStateAnsweredAllQuestions() {
        return this._completeState === this._completeStates.answeredAllQuestions;
    }

    /**
     * Returns if the given question was the first one shown
     *
     * @param {Question} question
     * @returns {null|boolean}
     */
    isFirstQuestionShown(question) {
        return (
            this._firstQuestionShown != null && this._firstQuestionShown.getQuestionId() === question.getQuestionId()
        );
    }

    /**
     *
     * @param {Question|null} currentQuestion
     */
    getPreviouslyAnsweredQuestion(currentQuestion) {
        let previousQuestion = currentQuestion.previousQuestion;
        let previouslyAnsweredQuestion = null;

        while (!previouslyAnsweredQuestion && previousQuestion) {
            // FIXME: Update Survey tests to enforce coverage.. running out of time
            /* istanbul ignore next */
            if (previousQuestion.isStateAnswered()) {
                // found it... set and it will be returned
                previouslyAnsweredQuestion = previousQuestion;
            } else {
                // go back one question more
                let backupPreviousQuestion = previousQuestion;
                previousQuestion = previousQuestion.previousQuestion;

                // see if there is a previous question group and if so, start stepping back through it
                if (!previousQuestion && backupPreviousQuestion.getQuestionGroup().previousQuestionGroup) {
                    previousQuestion = backupPreviousQuestion.getQuestionGroup().previousQuestionGroup.lastQuestion;
                }
            }
        }

        return previouslyAnsweredQuestion;
    }

    /**
     * Use this when you want to determine which question should be shown when the 'next' action is invoked.
     * It caters when users have gone 'back' then 'next' as well as 'next' to an unevaluated situation.
     *
     * @param {Question|null} currentQuestion
     * @return {Question|null}
     */
    getNextQuestionToShow(currentQuestion = null) {
        let nextQuestionToShow = null;

        if (!currentQuestion) {
            nextQuestionToShow = this.getNextUnansweredQuestion();
        } else {
            let nextQuestion = currentQuestion.nextQuestion;

            // FIXME: Update Survey tests to enforce coverage.. running out of time
            /* istanbul ignore next */
            // see if there is a previous question group and if so, start stepping through it
            if (!nextQuestion && currentQuestion.getQuestionGroup().nextQuestionGroup) {
                nextQuestion = currentQuestion.getQuestionGroup().nextQuestionGroup.firstQuestion;
            }

            // FIXME: Update Survey tests to enforce coverage.. running out of time
            /* istanbul ignore next */
            while (!nextQuestionToShow && nextQuestion) {
                if (nextQuestion.isStateAnswered() || (nextQuestion.isStateNotAsked() && nextQuestion.canBeAsked())) {
                    // found it... set and it will be returned
                    nextQuestionToShow = nextQuestion;
                } else {
                    // go forward one question more
                    let backupNextQuestion = nextQuestion;
                    nextQuestion = nextQuestion.nextQuestion;

                    // see if there is a previous question group and if so, start stepping through it
                    if (!nextQuestion && backupNextQuestion.getQuestionGroup().nextQuestionGroup) {
                        nextQuestion = backupNextQuestion.getQuestionGroup().nextQuestionGroup.firstQuestion;
                    }
                }
            }
        }

        // For navigational purposes we need to capture the first question shown to the user.
        if (!this._firstQuestionShown) {
            this._firstQuestionShown = nextQuestionToShow;
        }

        return nextQuestionToShow;
    }

    /**
     * Returns the next question that remains to be
     * @returns {Question|null}
     */
    getNextUnansweredQuestion() {
        let nextUnansweredQuestion = null;
        let nextQuestion = null;

        let questionGroup = this.getNextIncompleteQuestionGroup();

        // FIXME: Update Survey tests to enforce coverage.. running out of time
        /* istanbul ignore next */
        if (questionGroup) {
            nextQuestion = questionGroup.firstQuestion;

            while (!nextUnansweredQuestion && nextQuestion) {
                if (!nextQuestion.hasBeenResolved() && nextQuestion.canBeAsked()) {
                    // found it... set and it will be returned
                    nextUnansweredQuestion = nextQuestion;
                } else {
                    // go forward one question more
                    let backupNextQuestion = nextQuestion;
                    nextQuestion = nextQuestion.nextQuestion;

                    // see if there is a previous question group and if so, start stepping back through it
                    if (!nextQuestion && backupNextQuestion.getQuestionGroup().nextQuestionGroup) {
                        nextQuestion = backupNextQuestion.getQuestionGroup().nextQuestionGroup.firstQuestion;
                    }
                }
            }
        }

        return nextUnansweredQuestion;
    }

    getCompletionStatistics() {
        let totalQuestions = 0;
        let completedQuestions = 0;

        for (let questionGroup of this._questionGroups) {
            totalQuestions += questionGroup.getQuestions().length;

            for (let question of questionGroup.getQuestions()) {
                if (question.hasBeenResolved()) {
                    completedQuestions++;
                }
            }
        }

        return {
            totalQuestions,
            completedQuestions
        };
    }

    getAnsweredQuestion() {
        if (this._questionGroups == null || this._questionGroups.length === 0) {
            return [];
        }

        return this._questionGroups.reduce((result, qg) => {
            const answered = qg.getQuestions().filter(q => q.hasBeenResolved());
            return [...result, ...answered];
        }, []);
    }

    /**
     *
     * @returns {*|{valid: Boolean, schema: Object, validRuleGroups: {name: String, icon: string, validRules: Array}[]}}
     */
    evaluatePreConditionRuleSet() {
        if (this._userJourneyPage == null) {
            return null;
        }

        return this._userJourneyPage.evaluatePreConditionRuleSet();
    }

    get locale() {
        return this._schema.locale;
    }

    get isBpRetestSurvey() {
        return !!this._schema?.isBpRetest;
    }
}
