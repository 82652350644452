import Answer from "./Answer";
import { Validation, DateValidator, NumberValidator, TextValidator, RegexValidator } from "../Validation";
import RuleSet from "../Rules/RuleSet";

export default class Question {
    /**
     *
     * @param {{}}schema
     * @param questionGroup {QuestionGroup}
     */
    constructor(schema, questionGroup) {
        this._schema = schema;
        this._questionGroup = questionGroup;
        this._answers = [];
        this._questionStates = {
            notAsked: "notAsked", // never been evaluated. indicates the user never reached this question
            didNotQualify: "didNotQualify", // question rules stopped this question being asked
            answered: "answered", // user provided an answer
            answersCleared: "answersCleared", // answers where provided but removed
            answerPreFilled: "answerPreFilled"
        };
        this._questionState = this._questionStates.notAsked;
        this._previousQuestion = null;
        this._nextQuestion = null;
        this._preConditionRuleSet = null;
        this._exitConditionRuleSet = null;
    }

    /**
     * Set the answer of the question from the associated Response.
     * Due to the the Survey evaluation process we can not set the the state of the question to notAsked if there is no previous answer. see SU-965
     *
     * @returns {Question}
     */
    prePopulateWithResponse() {
        if (!this.isStateAnswered() && !this.isStateDidNotQualify()) {
            /* istanbul ignore else */
            let responseUtility = this._questionGroup.getSurvey().getResponse();
            if (responseUtility) {
                let previousAnswers = responseUtility.getAnswersForPreFill();

                if (previousAnswers.hasOwnProperty(this.getCode())) {
                    this._answers = previousAnswers[this.getCode()];
                    this._questionState = this._questionStates.answerPreFilled;
                }
            }
        }

        return this;
    }

    /**
     *
     * @returns {bool}
     */
    getAnswersContainPII() {
        return this._schema.answersContainPII;
    }

    // /**
    //  * TODO: REALLY QUESTIONING NEED FOR THIS... IF preconditions exist and they fail, then it can't be asked. Answer will not be found in Response
    //  * Returns if this question's preconditions have enough for answers
    //  *
    //  * @return {Boolean}
    //  * @private
    //  */
    // _allPreConditionQuestionsHaveAnswers() {
    //
    //     let allPreConditionQuestionsHaveAnswers = true;
    //     /** @type {Object|null|undefined} preConditions */
    //     const preConditions = this._schema.preConditions;
    //
    //     if (preConditions) {
    //
    //         for (let questionCode in preConditions) {
    //
    //             let questionModel = this._questionGroup.getSurvey().getQuestionByCode(questionCode);
    //
    //             // do we have enough information to evaluate anyway
    //             if (!questionModel || (!questionModel.hasStateAnsweredByPreFill() && !questionModel.hasStateAnswered())) {
    //                 allPreConditionQuestionsHaveAnswers = false;
    //                 break; // no need to continue search
    //             }
    //         }
    //     }
    //
    //     return allPreConditionQuestionsHaveAnswers;
    // }

    /**
     * Returns if this question's pre-conditions succeed or fail
     *
     * @return {Boolean}
     */
    canBeAsked() {
        if (!this._preConditionRuleSet) {
            this._preConditionRuleSet = RuleSet.createFromAnswerRuleSchema(
                this._schema.preConditions,
                this._questionGroup.getSurvey().getResponse(),
                { noRulesResult: true }
            );
        }

        // NB: trying to understand why blue pre condition from schema is failing in storybook
        return this._preConditionRuleSet.matches();
    }

    /**
     * Set the state of the question to notAsked
     */
    setStateNotAsked() {
        this._setQuestionState(this._questionStates.notAsked);
    }

    setStateDidNotQualify() {
        this._setQuestionState(this._questionStates.didNotQualify);
    }

    /**
     *
     * @param state
     * @returns {Question}
     * @private
     */
    _setQuestionState(state) {
        this._questionState = state;
        return this;
    }

    /**
     * Does this Question have the given state? Create hasAStateXXX() functions for public interfacing
     *
     * @param state
     * @returns {boolean}
     * @private
     */
    _isQuestionState(state) {
        return this._questionState === state;
    }

    /**
     * The default state. Implies that User has been asked this question yet. Also means that the qualifying conditions
     * have not been assessed yet
     *
     * @returns {boolean}
     */
    isStateNotAsked() {
        return this._isQuestionState(this._questionStates.notAsked);
    }

    /**
     * Question conditions means the user could not be asked this question
     *
     * @returns {boolean}
     */
    isStateDidNotQualify() {
        return this._isQuestionState(this._questionStates.didNotQualify);
    }

    /**
     * Question state where this question has been answered previously and has answered this instance of it
     *
     * @returns {boolean}
     */
    isStateAnsweredByPreFill() {
        return this._isQuestionState(this._questionStates.answerPreFilled);
    }

    /**
     * Returns if the user has provided an answer to this Question
     * @returns {boolean}
     */
    isStateAnswered() {
        return this._isQuestionState(this._questionStates.answered);
    }

    /**
     * Means the user has replied but has also changed their answer, yet didn't answer it again (likely represents a programmatic error)
     * @returns {boolean}
     */
    isStateAnswersCleared() {
        return this._isQuestionState(this._questionStates.answersCleared);
    }

    /**
     * Returns the question type determined in the schema. Create isQuestionTypeXXXX() for public interfacing
     *
     * @param code
     * @returns {boolean}
     * @private
     */
    _isQuestionType(code) {
        return this._schema.surveyQuestionType.code.toLowerCase() === code.toLowerCase();
    }

    /**
     * Is a Text type Question
     * @returns {boolean}
     */
    isQuestionTypeText() {
        return this._isQuestionType("TEXT");
    }

    /**
     * Is a Number type Question
     * @returns {boolean}
     */
    isQuestionTypeNumber() {
        return this._isQuestionType("NUMBER");
    }

    /**
     * Is a Choice type Question
     * @returns {boolean}
     */
    isQuestionTypeChoice() {
        return this._isQuestionType("CHOICE");
    }

    isQuestionTypeMultipleAnswer() {
        return this._isQuestionType("MULTIPLE_ANSWER");
    }

    /**
     * Is a Date type Question
     * @returns {boolean}
     */
    isQuestionTypeDate() {
        return this._isQuestionType("DATE");
    }

    /**
     * Is a Barcode type Question
     * @returns {boolean}
     */
    isQuestionTypeBarcode() {
        return this._isQuestionType("BARCODE");
    }

    /**
     * Returns if this Question has reached a resolution as part of the user journey by either being answered or not qualifying to be answered
     *
     * @returns {boolean}
     */
    hasBeenResolved() {
        return this.hasBeenAnswered() || this.isStateDidNotQualify();
    }

    /**
     * Returns if this Question has been answered by the user or from a 'previous' set of answers
     *
     * @returns {boolean}
     */
    hasBeenAnswered() {
        // evaluate if this answer has already been answered
        this.prePopulateWithResponse();
        return this.isStateAnswered() || this.isStateDidNotQualify() || this.isStateAnsweredByPreFill();
    }

    /**
     * Adds an Answer to the answer array for this Question
     *
     * @param answer
     * @returns {boolean|string[]}
     */
    addAnswer(answer) {
        const outcome = this.validateAnswer(answer);

        if (outcome === true) {
            this._answers.push(new Answer(answer));
            this._questionState = this._questionStates.answered;

            if (this._hasTriggeredExitCondition()) {
                this.getQuestionGroup()
                    .getSurvey()
                    .setCompleteByQuestionExitCondition();
            } else {
                this.getQuestionGroup()
                    .getSurvey()
                    .evaluateSurveyState();
            }
            return true;
        } else {
            return outcome;
        }
    }

    addMultipleAnswers(answers) {
        if (!Array.isArray(answers) || answers.length === 0) {
            return [`invalid type for answers: ${typeof answers}`];
        }

        // TODO: revisit to see how we are supposed to deal with the validation
        for (let answer of answers) {
            this._answers.push(new Answer(answer));
            this._questionState = this._questionStates.answered;
        }

        if (this._hasTriggeredExitCondition()) {
            this.getQuestionGroup()
                .getSurvey()
                .setCompleteByQuestionExitCondition();
        } else {
            this.getQuestionGroup()
                .getSurvey()
                .evaluateSurveyState();
        }

        return true;
    }

    /**
     *
     * @returns {*|boolean}
     * @private
     */
    _hasTriggeredExitCondition() {
        if (!this._exitConditionRuleSet) {
            this._exitConditionRuleSet = RuleSet.createFromAnswerRuleSchema(
                this._schema.exitConditions,
                this._questionGroup.getSurvey().getResponse()
            );
        }

        return this._exitConditionRuleSet.matches();
    }

    /**
     *
     * @param value
     * @return {boolean|string[]}
     */
    validateAnswer(value) {
        const configuration = this.getValidationConfiguration();
        const validationUtility = new Validation();

        switch (true) {
            case this.isQuestionTypeDate():
                validationUtility.addValidator(new DateValidator(configuration));
                validationUtility.addValidator(new RegexValidator(configuration));
                break;

            case this.isQuestionTypeNumber():
                validationUtility.addValidator(new NumberValidator(configuration));
                validationUtility.addValidator(new RegexValidator(configuration));
                break;

            case this.isQuestionTypeText():
                validationUtility.addValidator(new TextValidator(configuration));
                validationUtility.addValidator(new RegexValidator(configuration));
                break;

            case this.isQuestionTypeChoice():
                validationUtility.addValidator(new TextValidator());
                validationUtility.addValidator(new RegexValidator(configuration));
                break;
        }

        const errors = validationUtility.validate(value, configuration.isRequired);
        return errors.length === 0 ? true : errors;
    }

    /**
     *
     * @returns {boolean}
     */
    isRequired() {
        return this.getValidationConfiguration().isRequired === true;
    }

    /**
     * Deletes all previously captured answers
     *
     * @returns {Question}
     */
    clearAnswers(evaluateSurveyState = true) {
        this._answers = [];
        this._questionState = this._questionStates.answersCleared;
        if (evaluateSurveyState) {
            this.getQuestionGroup()
                .getSurvey()
                .evaluateSurveyState();
        }
        return this;
    }

    getAnswers() {
        return this._answers;
    }

    /**
     *
     * @param {Answer[]} answers
     */
    setAnswers(answers) {
        this._answers = answers;
    }

    getHelpText() {
        return this._schema.helpText;
    }

    getDisplayText() {
        return this._schema.displayText;
    }

    getQuestionId() {
        return this._schema.surveyQuestionId;
    }

    getPosition() {
        return this._schema.position;
    }

    getChoices() {
        let choices = [];
        if (this.isQuestionTypeChoice() || this.isQuestionTypeMultipleAnswer()) {
            choices = this._schema.configuration.choice.choices;
        }

        return choices;
    }

    getChoiceSize() {
        return this._schema.configuration.choice.size;
    }

    /**
     * Get the display options
     * @returns {string}
     */
    _getDisplayOption() {
        return this.isQuestionTypeChoice() ? this._schema.configuration.displayOption : "";
    }

    /**
     * Get the question icons. hasOwnProperty is in play while not all schemas have this config key
     *
     * @returns {string} or null
     */
    getQuestionIcon() {
        return this._schema.configuration.hasOwnProperty("icon") ? this._schema.configuration.icon : null;
    }

    getCode() {
        return this._schema.code;
    }

    getValidationConfiguration() {
        const defaultConfiguration = {
            isRequired: true
        };

        let configuration = { ...defaultConfiguration };

        if (this._schema.configuration.hasOwnProperty("validation")) {
            configuration = {
                ...configuration,
                ...this._schema.configuration.validation
            };
        }

        return configuration;
    }

    /**
     *
     * @returns {QuestionGroup}
     */
    getQuestionGroup() {
        return this._questionGroup;
    }

    /**
     *
     * @returns {Object}
     */
    toJSON() {
        return {
            schema: this._schema,
            questionStates: this._questionStates,
            questionState: this._questionState,
            answers: this._answers
        };
    }

    /**
     * Update this instance with the given JSON. Assumes JSON is of the correct format
     *
     * @param questionJSON
     */
    fromJSON(questionJSON) {
        // if (questionJSON.schema.code === 'DATE_OF_BIRTH') {
        //     console.log("memememememe");
        // }
        for (let a = 0; a < questionJSON.answers.length; a++) {
            let answerObject = questionJSON.answers[a];
            // when replaying PII Question we still need to create the answer record despite the answer being null
            // #chickenEgg (need Response utility generated from the Response model data before populating with the PII DTO)
            // FIXME: ideally not ignored. Need Response mock data with PII scrubbed
            if (this.getAnswersContainPII()) {
                let piiAnswer = new Answer(answerObject.answer);
                piiAnswer.piiScrubbed = answerObject.piiScrubbed;
                this._answers.push(piiAnswer);
            } else {
                this._answers.push(new Answer(questionJSON.answers[a].answer));
                //this.addAnswer(questionJSON.answers[a].answer);
            }
        }

        this._setQuestionState(questionJSON.questionState);
    }

    /**
     *
     * @param {Question} question
     */
    set previousQuestion(question) {
        if (!this._previousQuestion || this._previousQuestion.getQuestionId() !== question.getQuestionId()) {
            this._previousQuestion = question;
            question.nextQuestion = this;
        }
    }

    /**
     *
     * @returns {Question|null}
     */
    get previousQuestion() {
        return this._previousQuestion;
    }

    /**
     *
     * @param {Question} question
     */
    set nextQuestion(question) {
        if (!this._nextQuestion || this._nextQuestion.getQuestionId() !== question.getQuestionId()) {
            this._nextQuestion = question;
            question.previousQuestion = this;
        }
    }

    get nextQuestion() {
        return this._nextQuestion;
    }

    isDisplayOptionStacked() {
        return this._getDisplayOption() === "stacked";
    }

    isDisplayOptionSideBySide() {
        return this._getDisplayOption() === "sidebyside";
    }

    /**
     * Returns the meta associated to the Question. Because we don't have migrations for JSON structures yet,
     * persisted Question schemas will not have this property so we have to check for its existence.
     *
     * @return {Object}
     */
    get meta() {
        return this._schema.hasOwnProperty("meta") ? this._schema.meta : {};
    }

    get marketoField() {
        return this._schema ? this._schema.marketoField : null;
    }

    getFirstAnswerValue() {
        const answers = this.getAnswers();
        return answers && answers.length > 0 ? answers[0].getRawAnswer() : null;
    }

    getLastAnswerValue() {
        const answers = this.getAnswers();
        return answers && answers.length > 0 ? answers[answers.length - 1].getRawAnswer() : null;
    }
}
