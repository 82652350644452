import journeyMap, { WaistMeasureQuestionCodes, Answers } from "../../UserJourney/UserJourneyMap";
import CoreUtils from "../../core/coreUtils";
import InvalidArgumentError from "../../Errors/InvalidArgumentError";
import moment from "moment";

const totalRiskLevel = 3;

const ranges = {
    low: {
        key: "low",
        label: "Low",
        range: "< 6"
    },
    intermediate: {
        key: "intermediate",
        label: "Intermediate",
        range: "6-11"
    },
    high: {
        key: "high",
        label: "High",
        range: "12+"
    }
};

const AusDiabetesGuidelineDefinition = [
    {
        min: 12,
        max: 50,
        key: "high",
        label: "High",
        rangeInfo: "12+",
        riskLevel: 3 / (totalRiskLevel + 1),
        ranges: ranges
    },
    {
        min: 6,
        max: 11,
        key: "intermediate",
        label: "Intermediate",
        rangeInfo: "6-11",
        ranges: ranges,
        riskLevel: 2 / (totalRiskLevel + 1)
    },
    { min: 0, max: 5, key: "low", label: "Low", rangeInfo: "< 6", ranges: ranges, riskLevel: 1 / (totalRiskLevel + 1) }
];

/**
 * Ref: http://www.health.gov.au/internet/main/publishing.nsf/Content/diabetesRiskAssessmentToolStatic
 */
export default class AusDiabetesRisk {
    constructor(answers) {
        this.score = AusDiabetesRisk.calcScore(answers);
        this.guideline = AusDiabetesRisk.getGuideLine(this.score);
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "DIABETES";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "DOH_DIABETES";
    }

    isLow() {
        return this.guideline.key === AusDiabetesGuidelineDefinition[2].key;
    }

    isIntermediate() {
        return this.guideline.key === AusDiabetesGuidelineDefinition[1].key;
    }

    isHigh() {
        return this.guideline.key === AusDiabetesGuidelineDefinition[0].key;
    }

    /**
     * Returns an object representing all labels corresponding to this guideline defination
     *
     * @returns {object}
     */
    static get labels() {
        return {
            low: "Low",
            intermediate: "Intermediate",
            high: "High"
        };
    }

    /**
     * Calculates the overall score.
     * @param answers
     * @returns {*}
     */
    static calcScore(answers) {
        let dob = answers[journeyMap.questionCodes.dateOfBirth];
        let age = dob ? moment().diff(dob, "years", false) : null;
        let waistMeasure = this.getWaistMeasureFromAnswers(answers);

        return (
            this.ageScore(age) +
            this.genderScore(answers[journeyMap.questionCodes.gender]) +
            this.descentScore(answers[journeyMap.questionCodes.descent]) +
            this.countryOfBirthScore(answers[journeyMap.questionCodes.countryOfBirth]) +
            this.diabetesFamilyHistoryScore(answers[journeyMap.questionCodes.diabetesFamilyHistory]) +
            this.highBloodGlucoseScore(answers[journeyMap.questionCodes.hasHighBloodGlucose]) +
            this.takeHighBloodPressureMedsScore(answers[journeyMap.questionCodes.isTakingHighBloodPressureMed]) +
            this.isSmokerScore(answers[journeyMap.questionCodes.doYouSmokeExcludingVaping]) +
            this.everydayEatVegetablesScore(answers[journeyMap.questionCodes.eatsFruitsVeggiesDaily]) +
            this.doesMinimumPhysicalActivityPerWeekScore(answers[journeyMap.questionCodes.weeklyPhysicalActivity]) +
            this.waistMeasurementScore(waistMeasure)
        );
    }

    /**
     * Gets age score.
     * @param age
     * @returns {number}
     */
    static ageScore(age) {
        if (age == null || Number.isNaN(age)) {
            throw this.invalidArgument("age");
        }

        switch (true) {
            case CoreUtils.isWithin(age, -Infinity, 35, true, false):
                return 0;

            case CoreUtils.isWithin(age, 35, 45, true, false):
                return 2;

            case CoreUtils.isWithin(age, 45, 55, true, false):
                return 4;

            case CoreUtils.isWithin(age, 55, 65, true, false):
                return 6;

            case CoreUtils.isWithin(age, 65, Infinity, true, false):
                return 8;
        }
    }

    /**
     * Gets gender score.
     * @param gender
     * @returns {number}
     */
    static genderScore(gender) {
        if (gender == null) {
            throw this.invalidArgument("gender");
        }

        switch (gender.toLowerCase()) {
            case journeyMap.answersLowerCase.Gender.female:
                return 0;

            case journeyMap.answersLowerCase.Gender.male:
                return 3;

            default:
                throw this.invalidArgument("gender");
        }
    }

    /**
     * Gets descent score.
     * @param descent
     * @returns {number}
     */
    static descentScore(descent) {
        if (descent == null) {
            throw this.invalidArgument("descent");
        }

        switch (descent.toLowerCase()) {
            case journeyMap.answersLowerCase.Descent.other:
                return 0;

            case journeyMap.answersLowerCase.Descent.aboriginalOrTorres:
            case journeyMap.answersLowerCase.Descent.pacificOrMaori:
                return 2;

            default:
                throw this.invalidArgument("descent");
        }
    }

    /**
     * Gets the country of birth score.
     * @param countryOfBirth
     * @returns {number}
     */
    static countryOfBirthScore(countryOfBirth) {
        if (countryOfBirth == null) {
            throw this.invalidArgument("country of birth");
        }

        switch (countryOfBirth.toLowerCase()) {
            case journeyMap.answersLowerCase.CountryOfBirth.australia:
            case journeyMap.answersLowerCase.CountryOfBirth.other:
                return 0;

            case journeyMap.answersLowerCase.CountryOfBirth.asia:
            case journeyMap.answersLowerCase.CountryOfBirth.middleEast:
            case journeyMap.answersLowerCase.CountryOfBirth.northAfrica:
            case journeyMap.answersLowerCase.CountryOfBirth.southernEurope:
                return 2;

            default:
                throw this.invalidArgument("country of birth");
        }
    }

    /**
     * Gets the diabetes family history score.
     * @param haveFamilyHistory
     * @returns {*|*}
     */
    static diabetesFamilyHistoryScore(haveFamilyHistory) {
        return this.yesNoScore(haveFamilyHistory, 0, 3, "diabetes family history");
    }

    /**
     * Gets high blood glucose score.
     * @param haveHighBloodGlucose
     * @returns {*|*}
     */
    static highBloodGlucoseScore(haveHighBloodGlucose) {
        return this.yesNoScore(haveHighBloodGlucose, 0, 6, "high blood glucose");
    }

    /**
     * Gets taking high blood pressure score.
     * @param takingMedicine
     * @returns {*|*}
     */
    static takeHighBloodPressureMedsScore(takingMedicine) {
        return this.yesNoScore(takingMedicine, 0, 2, "taking high blood pressure medicine");
    }

    /**
     * Gets is-smoker score.
     * @param doYouSmoke
     * @returns {*|*}
     */
    static isSmokerScore(doYouSmoke) {
        switch (doYouSmoke) {
            case Answers.SmokingDoYouSmokeNew.yes:
                return 2;
            case Answers.SmokingDoYouSmokeNew.usedTo:
            case Answers.SmokingDoYouSmokeNew.someDays:
            case Answers.SmokingDoYouSmokeNew.never:
                return 0;
            default:
                throw this.invalidArgument(name);
        }
    }

    /**
     * Gets eating vegetable and fruits on daily basis score.
     * @param everydayEatVegetables
     * @returns {*|*}
     */
    static everydayEatVegetablesScore(everydayEatVegetables) {
        return this.yesNoScore(everydayEatVegetables, 1, 0, "eat vegetables everyday");
    }

    /**
     * Gets minimum physical activity score.
     * @param doesMinimumPhysicalActivityPerWeek
     * @returns {*|*}
     */
    static doesMinimumPhysicalActivityPerWeekScore(doesMinimumPhysicalActivityPerWeek) {
        return this.yesNoScore(doesMinimumPhysicalActivityPerWeek, 2, 0, "doing at least 2.5 hours per week");
    }

    /**
     * Gets the waist measure score.
     * @param waistMeasure
     * @returns {number}
     */
    static waistMeasurementScore(waistMeasure) {
        if (waistMeasure == null) {
            throw this.invalidArgument("waist measure");
        }

        switch (waistMeasure.toLowerCase()) {
            case journeyMap.answersLowerCase.WaistMeasureAboriginalMale.thin:
            case journeyMap.answersLowerCase.WaistMeasureAboriginalFemale.thin:
            case journeyMap.answersLowerCase.WaistMeasureNotAboriginalMale.thin:
            case journeyMap.answersLowerCase.WaistMeasureNotAboriginalFemale.thin:
                return 0;

            case journeyMap.answersLowerCase.WaistMeasureAboriginalMale.normal:
            case journeyMap.answersLowerCase.WaistMeasureAboriginalFemale.normal:
            case journeyMap.answersLowerCase.WaistMeasureNotAboriginalMale.normal:
            case journeyMap.answersLowerCase.WaistMeasureNotAboriginalFemale.normal:
                return 4;

            case journeyMap.answersLowerCase.WaistMeasureAboriginalMale.thick:
            case journeyMap.answersLowerCase.WaistMeasureAboriginalFemale.thick:
            case journeyMap.answersLowerCase.WaistMeasureNotAboriginalMale.thick:
            case journeyMap.answersLowerCase.WaistMeasureNotAboriginalFemale.thick:
                return 7;

            default:
                throw this.invalidArgument("waist measure");
        }
    }

    /**
     * Gets the score for a generic yes-no question.
     * @param answer
     * @param noScore
     * @param yesScore
     * @param name
     * @returns {number}
     */
    static yesNoScore(answer, noScore, yesScore, name = null) {
        if (answer == null) {
            throw this.invalidArgument(name);
        }

        switch (answer.toLowerCase()) {
            case "yes":
                return yesScore;

            case "no":
                return noScore;

            default:
                throw this.invalidArgument(name);
        }
    }

    /**
     * Gets the answers for waist measure question.
     * @param answers
     * @returns {null}
     */
    static getWaistMeasureFromAnswers(answers) {
        let codes = Object.values(WaistMeasureQuestionCodes).filter(code => answers[code] != null);
        return codes.length !== 1 ? null : answers[codes[0]];
    }

    /**
     * Creates an invalid argument exception for this class.
     * @param name
     * @returns {InvalidArgumentError}
     */
    static invalidArgument(name) {
        return new InvalidArgumentError(`Diabetes Scoring: Not a valid ${name ? name : "answer"}`);
    }

    /**
     * Gets the AUSDRSIK guideline for the given score.
     * @param score
     * @returns {*}
     */
    static getGuideLine(score) {
        return AusDiabetesGuidelineDefinition.find(gl => CoreUtils.isWithin(score, gl.min, gl.max));
    }
}

export { AusDiabetesGuidelineDefinition };
