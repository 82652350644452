import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { stationTheme } from "@sisuwellness/web-components";

const StyleType = {
    primary: "Primary",
    secondary: "Secondary"
};

const StyledButton = styled.button`
    font-size: ${props => props.theme.actionButtons.text.size};
    font-weight: ${props => props.theme.actionButtons.text.weight};

    border-radius: ${props => props.theme.actionButtons.borderRadius};
    filter: ${props => props.theme.actionButtons.shadow.normal};
    padding: 0;
    user-select: none;
    text-transform: uppercase;

    :focus {
        outline: none;
    }

    :disabled {
        background-color: ${props => props.theme.colours.flat.gray.hex};
    }
`;

const StyledPrimaryButton = styled(StyledButton)`
    background-color: ${props => props.theme.actionButtons.primary.backgroundColor.normal};
    color: ${props => props.theme.actionButtons.primary.color.normal};
    border: ${props => props.theme.actionButtons.primary.border.normal};

    :active {
        background-color: ${props => props.theme.actionButtons.primary.backgroundColor.active};
        filter: ${props => props.theme.actionButtons.shadow.active};
    }
`;

const StyledSecondaryButton = styled(StyledButton)`
    background-color: ${props => props.theme.actionButtons.secondary.backgroundColor.normal};
    color: ${props => props.theme.actionButtons.secondary.color.normal};
    border: ${props => props.theme.actionButtons.secondary.border.normal};

    :active {
        color: ${props => props.theme.actionButtons.secondary.color.active};
        border: ${props => props.theme.actionButtons.secondary.border.active};
        filter: ${props => props.theme.actionButtons.shadow.active};
    }

    :disabled {
        color: ${props => props.theme.colours.flat.white.hex};
        border-width: 0;
    }
`;

class ActionButton extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        styleType: PropTypes.string,
        onClick: PropTypes.func,
        maxWidth: PropTypes.string,
        maxHeight: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        styleType: "Primary"
    };

    onClick(e) {
        if (this.props.onClick !== undefined) {
            this.props.onClick(e);
        }
    }

    render() {
        let button;
        switch (this.props.styleType) {
            case "Primary":
                button = (
                    <StyledPrimaryButton
                        className={`action-button-${this.props.className}`}
                        onClick={e => this.onClick(e)}
                        disabled={this.props.disabled}
                    >
                        {this.props.label}
                    </StyledPrimaryButton>
                );
                break;

            case "Secondary":
                button = (
                    <StyledSecondaryButton
                        className={`action-button-sec-${this.props.className}`}
                        onClick={e => this.onClick(e)}
                        disabled={this.props.disabled}
                    >
                        {this.props.label}
                    </StyledSecondaryButton>
                );
                break;

            default:
                button = (
                    <StyledPrimaryButton
                        className={`action-button-${this.props.className}`}
                        onClick={e => this.onClick(e)}
                        disabled={this.props.disabled}
                    >
                        {this.props.label}
                    </StyledPrimaryButton>
                );
                break;
        }

        return button;
    }
}

const StyledActionButton = styled(ActionButton)`
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    max-height: ${props => (props.maxHeight ? props.maxHeight : stationTheme.actionButtons.height)};
    max-width: ${props => (props.maxWidth ? props.maxWidth : stationTheme.actionButtons.width)};
    width: 100%;
    height: 100%;
`;

export { StyledActionButton as default, StyleType, StyledSecondaryButton };
